import React, { useState, useRef, useEffect } from 'react';
import { MDBFilters } from 'mdb-react-filters';
import {
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBSideNavCollapse,
  MDBIcon,
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCheckbox,
  MDBCol,
  MDBDatepicker,
  MDBSelect,
  MDBInputGroup,
  MDBDatatable,
  MDBAccordion,
  MDBAccordionItem,
  MDBBadge,
  MDBTable,
  MDBTableBody,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter
} from 'mdb-react-ui-kit';
import Icon_Svsf from "../../svg/svg_icon_svsf";
import {
  useLoaderData,
  useNavigate,
  Link,
  useNavigationType
} from "react-router-dom";
import { fetch_get, fetch_post, fetch_delete } from "../../../services/common";
import EventOverviewPageModal from "./EventOverviewPageModal";


const customWeekdaysNarrow = ['S', 'M', 'T', 'O', 'T', 'F', 'L'];
const customMonthsFull = ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December',];
const customWeekdaysShort = ['Sön', 'Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör'];
const customMonthsShort = ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];


export const loader = async ({ params }) => {


  // const resJson = JSON.parse(sessionStorage.getItem('tmp_event_list'));
  // resJson['active'] = JSON.parse(sessionStorage.getItem('tmp_event_list_active'));
  // resJson['filter'] = JSON.parse(sessionStorage.getItem('tmp_event_list_filter'));
  // resJson['position'] = sessionStorage.getItem('tmp_event_list_position');
  // console.log('resJson_active', resJson['position']);

  return resJson;
};

const HeadObject = (it) => {
  return (
    <>

      <span className="d-inline-flex bd-highlight align-self-start text-nowrap flex-column">
        <strong>{it.item.start_date}</strong>
        {/* <span className="d-inline-flex pt-4 align-self-end"> */}

        <MDBBadge className="d-block d-sm-none mt-2" pill light color={it.item.status_color}>{it.item.status_name}</MDBBadge>
        <span className="ps-2 d-block d-sm-none align-self-center" data-bs-toggle="tooltip" data-bs-placement="top" title="Sätt i min lista"><MDBIcon className="float-end" far icon="heart" /></span>
        {/* </span> */}

      </span>
      <span className="d-inline-flex bd-highlight flex-column align-self-start flex-md-fill px-3 text-truncate" style={{ maxWidth: 250, minWidth: 250, }}>
        <strong title={it.item.name}>{it.item.name}</strong>
        <span className="font-monospace text-muted pt-2 fst-italic d-sm-block d-md-block d-lg-none">{it.item.arrangorer}</span>
        <span className="font-monospace text-muted pt-2 fst-italic ">{it.item.d2_name}</span>
      </span>
      <span className="d-lg-inline-flex bd-highlight flex-md-column d-none d-sm-none d-md-none">
        <strong>{it.item.arrangorer}</strong>
        <span className="font-monospace text-muted pt-2 fst-italic">Anmälan från: {it.item.anmalan_start_date} till {it.item.anmalan_end_date}</span>

      </span>
      {/*       <span className="d-inline-flex bd-highlight flex-column flex-fill align-self-start px-3 d-none d-sm-none d-md-none d-lg-block">
        <strong>{it.item.arrangorer}</strong>
        <span className="font-monospace text-muted pt-2 fst-italic">Anmälan från: {it.item.anmalan_start_date} till {it.item.anmalan_end_date}</span>

      </span>  */}
      <span className="d-inline-flex flex-column px-2 flex-fill align-self-start d-none d-sm-block">
        <span>
          <span className="float-end p-1" data-bs-toggle="tooltip" data-bs-placement="top" title="Sätt i min lista"><MDBIcon className="float-end" far icon="heart" /></span>
          <MDBBadge className="float-end" pill light color={it.item.status_color}>{it.item.status_name}</MDBBadge>
        </span>
      </span>

    </>
  )
}

const mainStyles = {
  rubriker: {
    height: 20,
    position: 'absolute',
    top: '-10px',

  }
};





export default function EventsPage() {
  const [sidenavOpen, setSidenavOpen] = useState(false);
  const contentRef = useRef(null);

  const [filterModal, setFilterModal] = useState(false);
  const toggleOpen = () => setFilterModal(!filterModal);

  const [fullscreenXlModal, setFullscreenXlModal] = useState(false);
  const toggleOpenFullscreen = () => setFullscreenXlModal(!fullscreenXlModal);

  const OpenFullscreen = (e) => {
    //console.log('e', e);
    setCurrent(e);
    setFullscreenXlModal(!fullscreenXlModal);
  };

  const loadObject = useLoaderData();
  const [data, setData] = useState([]);
  const [load_data, setLoadData] = useState(null);
  //const [a_active, setActive] = useState([0, 'c2f7bb7a-c0e3-11ee-8f48-00505692ffcc', 'a53fb64d-c04e-11ee-8f48-00505692ffcc']);
  const [a_active, setActive] = useState([0]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.scrollY;
    console.log('position', position);
    sessionStorage.setItem('tmp_event_list_position', position);
    setScrollPosition(position);
  };

  const navigate = useNavigate();
  const navigationType = useNavigationType();
  //const [load_data, setLoadData] = useState([]);
  //const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [current, setCurrent] = useState("");
  const [lov_foreningar, setLovForeningar] = useState([]);
  const [lov_grenar, setLovGrenar] = useState([]);
  const [lov_grenar_discipliner, setLovGrenarDiscipliner] = useState([]);
  const [lov_kategorier, setLovKategorier] = useState([]);
  const [lov_distrikt, setLovDistrikt] = useState([]);
  const [lov_disciplin, setLovDisciplin] = useState([]);
  const [lov_disciplin1, setLovDisciplin1] = useState([]);
  const [lov_disciplin2, setLovDisciplin2] = useState([]);
  const [nnfilterValue, nnsetFilterValue] = useState(null);
  const [filterValue, setFilterValue] = useState(null);
  const [defaultfilterValue, setDefaultFilterValue] = useState(null);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  useEffect(() => {

    // setTimeout(() => {
    //   console.log('Scroll........', scrollPosition)
    //   window.scrollTo({ top: scrollPosition});
    // }, (100));

  }, [])

  useEffect(() => {

    console.log('navigationType', navigationType);
    let resJson = JSON.parse(sessionStorage.getItem('tmp_event_list'));
    let filter = JSON.parse(sessionStorage.getItem('tmp_event_list_filter'));
    let active = JSON.parse(sessionStorage.getItem('tmp_event_list_active'));
    if (navigationType === 'POP' && filter && active) {


      setData(resJson);
      setLoadData(resJson);


      if (active) {
        setActive(active);
      }


      console.log('filter......', filter);
      if (filter) {
        setFilterValue(filter);
      }

      let position = sessionStorage.getItem('tmp_event_list_position');
      if (position) {
        setScrollPosition(position);

        setTimeout(() => {
          console.log('Scroll........', position)
          window.scrollTo({ top: position, behavior: "instant", });
        }, (300));


      }



      setLoading(false);
    } else {
      fetchData();

    }

  }, []);

  const fetchData = async () => {
    await fetch_get('app/list/events/')
      .then(data => {
        // setData(data.data);
        setLoadData(data.data);

        sessionStorage.setItem('tmp_event_list', JSON.stringify(data.data));
        sessionStorage.removeItem("tmp_event_list_active");
        sessionStorage.removeItem("tmp_event_list_filter");
        sessionStorage.removeItem("tmp_event_list_position");
        setFilterValue(data.default_filter);
        setDefaultFilterValue(data.default_filter);
        // setFilterValue({
        //   start_date: '2024-03-01',
        //   end_date: '2024-12-31',
        //   gren: [],
        //   kategori: null,
        //   typ: null,
        //   forening: null,
        //   distrikt: null,
        //   free_text: ""
        // }); 
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  };

  useEffect(() => {
    console.log('load_data', load_data);
    if (load_data && filterValue) {
      console.log('filterValue', filterValue, filterValue.forening, filterValue.forening > 0);
      let filteredData = load_data.filter((row) => {
        return (row.kategori_id === filterValue.kategori || filterValue.kategori == 0)
          //xxxxxxx && (row.arrangorer_id ? row.arrangorer_id.indexOf('|' + filterValue.forening + '|') : false || !filterValue.forening)
          && (row.arrangorer_id && filterValue.forening > 0 ? row.arrangorer_id.split(',').includes(filterValue.forening) : false || filterValue.forening == 0)
          && (row.d_sportgren_id && filterValue.disciplin > 0 ? row.d_sportgren_id.split('_').includes(filterValue.disciplin) : false || filterValue.disciplin == 0)
          && (row.d_sportgren_id && filterValue.disciplin1 > 0 ? row.d_sportgren_id.split('_').includes(filterValue.disciplin1) : false || filterValue.disciplin1 == 0)
          && (row.d_sportgren_id && filterValue.disciplin2 > 0 ? row.d_sportgren_id.split('_').includes(filterValue.disciplin2) : false || filterValue.disciplin2 == 0)
          && (row.distrikt_id && filterValue.distrikt > 0 ? row.distrikt_id.split(',').includes(filterValue.distrikt) : false || filterValue.distrikt == 0)
          && (row.d1_sequence && filterValue.gren > 0 ? filterValue.gren.includes(row.d1_sequence) : false || filterValue.gren == 0)
          && (row.start_date >= filterValue.start_date && row.start_date <= filterValue.end_date)
          && (Object.keys(row).some(key => row[key] ? row[key].toUpperCase().includes(filterValue.free_text.toUpperCase()) : false))
          ;
        //return row.age > 40 && user.occupation === 'programmer';
      });
      //console.log('filteredData', filteredData);
      setData(filteredData);
    }

  }, [filterValue]);





  useEffect(() => {

    fetch_get('app/list/lov-foreningar')
      .then(data => {
        data.data.unshift({ "value": "0", "text": "Alla" });
        setLovForeningar(data.data);
        //console.log('setLovForeningar', data.data);
        //setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        //setLoading(false);
      });

    // fetch_get('app/list/lov-grenar')
    //   .then(data => {
    //     data.data.unshift({ "value": "0", "text": "Alla" });
    //     //setLovGrenar(data.data);

    //   })
    //   .catch(error => {
    //     console.error('Error fetching data:', error);
    //     //setLoading(false);
    //   });

    fetch_get('app/list/lov-kategorier')
      .then(data => {
        data.data.unshift({ "value": "0", "text": "Alla" });
        setLovKategorier(data.data);
        //console.log('setLovKategorier', data.data);
        //setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        //setLoading(false);
      });

    fetch_get('app/list/lov-distrikt')
      .then(data => {
        data.data.unshift({ "value": "0", "text": "Alla" });
        setLovDistrikt(data.data);
        console.log('setLovDistrikt', data.data);
        //setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        //setLoading(false);
      });

    // fetch_get('app/list/lov-distrikt')
    //   .then(data => {
    //     data.data.unshift({ "value": "0", "text": "Alla" });
    //     setLovDisciplin(data.data);
    //     console.log('setLovDisciplin', data.data);
    //     //setLoading(false);
    //   })
    //   .catch(error => {
    //     console.error('Error fetching data:', error);
    //     //setLoading(false);
    //   });

    fetch_get('app/list/lov-grenar-discipliner')
      .then(data => {
        setLovGrenarDiscipliner(data.data);

        let filteredData = data.data.filter((row) => {
          return (row.type === '10')
            ;

        });

        console.log(filteredData);
        filteredData.unshift({ "value": "0", "text": "Alla" });
        setLovGrenar(filteredData);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        //setLoading(false);
      });

  }, []);

  const list = (data) => {
    return (
      data.map((item, i) => {
        return (

          <MDBAccordionItem
            collapseId={item.key_id}
            headerTitle={<HeadObject item={item} />}
            key={i}

          >

            <div className="d-flex flex-column flex-md-row justify-content-around">
              <div className="d-flex flex-column flex-fill mb-3 order-2 order-sm-1">
                <div className="p-2 position-relative">
                  <div className="overview-label text-nowrap">Tävling</div>
                  {item.name || '-'}
                </div>
                <div className="p-2 position-relative">
                  <div className="overview-label text-nowrap">Arrangör</div>
                  {item.arrangorer || '-'}
                </div>
                <div className="p-2 position-relative">
                  <div className="overview-label text-nowrap">Typ Av tävling</div>
                  {item.type_name || '-'}
                </div>
                <div className="p-2 position-relative">
                  <div className="overview-label text-nowrap">Kategori</div>
                  {item.kategori_name || '-'}
                </div>
                <div className="p-2 position-relative overflow-wrap">
                  <div className="overview-label text-nowrap">Sportgren</div>
                  {item.d_sportgren
                    ? (item.d_sportgren.split(',').map(sg => <div>{sg}</div>))
                    : <div>-</div>
                  }
                </div>
                <hr className="hr" />
                <div className="p-2 position-relative css-fix">
                  <div className="overview-label text-nowrap">Plats</div>
                  {item.plats || '-'}
                </div>
                <div className="p-2 position-relative">
                  <div className="overview-label text-nowrap">Hemsida</div>
                  <a href={item.hemsida} target='_new'>{item.hemsida || '-'}</a>
                </div>
                <hr className="hr" />
                <div className="p-2 position-relative">
                  <div className="overview-label text-nowrap">Kontakt</div>
                  {item.kontakt_first_name || '-'}
                </div>
                <div className="p-2 position-relative">
                  <div className="overview-label text-nowrap">E-mail</div>
                  {item.kontakt_email || '-'}
                </div>
                <div className="p-2 position-relative">
                  <div className="overview-label text-nowrap">Telefon</div>
                  {item.kontakt_phone || '-'}
                </div>
              </div>
              <div className="d-flex flex-column flex-fill mb-3 order-3 order-sm-2">
                <div className="p-2 position-relative">
                  <div className="overview-label text-nowrap">Start datum</div>
                  {item.start_date || '-'}
                </div>
                <div className="p-2 position-relative">
                  <div className="overview-label text-nowrap">Slut datum</div>
                  {item.end_date || '-'}
                </div>
                <div className="p-2 position-relative">
                  <div className="overview-label text-nowrap">Öppnar för anmälan</div>
                  {item.anmalan_start_date || '-'}
                </div>
                <div className="p-2 position-relative">
                  <div className="overview-label text-nowrap">Sista anmälningsdatum</div>
                  {item.anmalan_end_date || '-'}
                </div>
                <div className="p-2 position-relative">
                  <div className="overview-label text-nowrap">Sista efteranmälningsdatum</div>
                  {item.efteranmalan_end_date || '-'}
                </div>
              </div>
              <div className="d-flex flex-column flex-fill mb-3 order-1 order-sm-3">
                <div className="p-2 position-relative" color={item.status_color}>
                  <div className="overview-label text-nowrap">Status</div>
                  {item.status_name || '-'}
                </div>

              </div>
            </div>


            <Link to={"/event-overview/" + item.key_id} className="float-end">
              <MDBBtn>Mer information</MDBBtn>
            </Link>
            <Link to={"/event-anmalan/" + item.key_id} className="float-end me-2">
              <MDBBtn>Anmälan</MDBBtn>
            </Link>

          </MDBAccordionItem>




        )
      })
    )
  }


  const advancedData = {
    columns: [
      { label: 'Start', field: 'start_date', sort: true },
      { label: 'Namn', field: 'name', sort: true },
    ],
    rows: data,
  };




  const setAccList = (e) => {

    setActive(e);
    //console.log(e);
    sessionStorage.setItem('tmp_event_list_active', JSON.stringify(e));

  };

  const onChange = (e, type, name = '') => {
    if (load_data) {
    console.log('onChange', e, type, name);
    let tmp_filterValue = filterValue;
    if (type === 'text') {

      //setFilterValue({ ...filterValue, [e.target.name]: e.target.value });
      tmp_filterValue = { ...tmp_filterValue, [e.target.name]: e.target.value };
    } else if (type === 'date') {

      //setFilterValue({ ...filterValue, [name]: e });
      tmp_filterValue = { ...tmp_filterValue, [name]: e };
    } else if (type === 'select_multi') {
      let grenArr = e.map((item) => item.value);

      //setFilterValue({ ...filterValue, [name]: grenArr });
      tmp_filterValue = { ...tmp_filterValue, [name]: grenArr };
    } else {
      //  select
      //setFilterValue({ ...filterValue, [name]: e.value });
      tmp_filterValue = { ...tmp_filterValue, [name]: e.value };
    }

    if (name === 'gren') {
      console.log('gren', e.value);
      // setFilterValue({ ...filterValue, ['disciplin']: 0 });
      tmp_filterValue = { ...tmp_filterValue, ['disciplin']: "0" };

      let filteredData = lov_grenar_discipliner.filter((row) => {
        return (row.parent_id === e.value)
          ;
      });


      console.log(filteredData);
      filteredData.unshift({ "value": "0", "text": "Alla" });
      setLovDisciplin(filteredData);
    }

    if (name === 'disciplin') {
      console.log('disciplin', e.value);
      // setFilterValue({ ...filterValue, ['disciplin']: 0 });
      tmp_filterValue = { ...tmp_filterValue, ['disciplin1']: "0" };

      let filteredData = lov_grenar_discipliner.filter((row) => {
        return (row.parent_id === e.value)
          ;
      });


      console.log(filteredData);
      filteredData.unshift({ "value": "0", "text": "Alla" });
      setLovDisciplin1(filteredData);
    }

    if (name === 'disciplin1') {
      console.log('disciplin1', e.value);
      // setFilterValue({ ...filterValue, ['disciplin']: 0 });
      tmp_filterValue = { ...tmp_filterValue, ['disciplin2']: "0" };

      let filteredData = lov_grenar_discipliner.filter((row) => {
        return (row.parent_id === e.value)
          ;
      });


      console.log(filteredData);
      filteredData.unshift({ "value": "0", "text": "Alla" });
      setLovDisciplin2(filteredData);
    }

    setFilterValue(tmp_filterValue);

    if (!loading) {
      sessionStorage.setItem('tmp_event_list_filter', JSON.stringify(tmp_filterValue));
    }

  }

  };

  const resetFilter = () => {
    console.log('resert filter', defaultfilterValue);
    setFilterValue(defaultfilterValue);

    sessionStorage.setItem('tmp_event_list_filter', JSON.stringify(defaultfilterValue));


  };

  // *****************************


  return (
    <>
      <MDBContainer className="py-4">

        <MDBModal
          animationDirection='left'
          open={filterModal}
          tabIndex='-1'
          setOpen={setFilterModal}
        >
          <MDBModalDialog
            position='top-left'
            side
            size='sm'
          // className='position-absolute top-0 start-0'
          >
            {filterValue && (
              <MDBModalContent className='h-100'>
                <MDBModalHeader className=''>
                  <MDBModalTitle>Filter</MDBModalTitle>
                  <MDBBtn
                    color='none'
                    className='btn-close'
                    onClick={toggleOpen}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <MDBContainer className='w-100 p-1 justify-content-left'>
                    <MDBRow className=''>
                      <MDBInputGroup className='' noBorder textAfter={<MDBIcon fas icon='search' />}>
                        <input
                          name='free_text'
                          className='form-control'
                          type='text'
                          placeholder='Text sök'
                          label='Text input'
                          value={filterValue.free_text}
                          onChange={(e) => onChange(e, 'text')}
                        />

                      </MDBInputGroup>
                    </MDBRow >

                    <MDBRow className='pt-3 px-2'>
                      <MDBDatepicker
                        name='start_date'
                        format='yyyy-mm-dd'
                        defaultValue='2024-01-01'
                        startDay={1}
                        weekdaysNarrow={customWeekdaysNarrow}
                        monthsFull={customMonthsFull}
                        weekdaysShort={customWeekdaysShort}
                        monthsShort={customMonthsShort}
                        inline
                        inputLabel='Från datum'
                        max={new Date(filterValue.end_date)}
                        value={filterValue.start_date}
                        onChange={(e) => onChange(e, 'date', 'start_date')}
                      // onChange={console.log('onchange')}
                      // onClosed={console.log('onClosed')}
                      // onClose={console.log('onClose')}
                      />
                    </MDBRow >
                    <MDBRow className='pt-3 px-2'>
                      <MDBDatepicker
                        name='end_date'
                        format='yyyy-mm-dd'
                        defaultValue='2024-12-31'
                        inline inputLabel='Till datum'
                        startDay={1}
                        weekdaysNarrow={customWeekdaysNarrow}
                        monthsFull={customMonthsFull}
                        weekdaysShort={customWeekdaysShort}
                        monthsShort={customMonthsShort}
                        min={new Date(filterValue.start_date)}
                        value={filterValue.end_date}
                        onChange={(e) => onChange(e, 'date', 'end_date')}
                      />
                    </MDBRow >
                    <MDBRow className='pt-2'>
                      <MDBSelect
                        name='gren'
                        label='Gren'
                        //noResultLabel="test"
                        // placeholder='Välj förening'
                        data={lov_grenar}
                        preventFirstSelected
                        value={filterValue.gren}
                        onValueChange={(e) => onChange(e, 'select', 'gren')}

                      //multiple
                      //selectAll={false}

                      />
                    </MDBRow>

                    {!(filterValue.gren=='0') && lov_disciplin.length>1 && (
                      <MDBRow className='pt-2'>
                        <MDBSelect
                          name='disciplin'
                          label='Disciplin'
                          data={lov_disciplin}
                          preventFirstSelected
                          value={filterValue.disciplin}
                          onValueChange={(e) => onChange(e, 'select', 'disciplin')}
                        />
                      </MDBRow>
                    )}

                    {!(filterValue.gren=='0') && !(filterValue.disciplin=='0') && lov_disciplin1.length>1 && (
                      <MDBRow className='pt-2'>
                        <MDBSelect
                          name='disciplin1'
                          label='Under Disciplin 1'
                          data={lov_disciplin1}
                          preventFirstSelected
                          value={filterValue.disciplin1}
                          onValueChange={(e) => onChange(e, 'select', 'disciplin1')}
                        />
                      </MDBRow>
                    )}
                    {!(filterValue.gren=='0') && !(filterValue.disciplin1=='0') && lov_disciplin2.length>1 && (
                      <MDBRow className='pt-2'>
                        <MDBSelect
                          name='disciplin2'
                          label='Under Disciplin 2'
                          data={lov_disciplin2}
                          preventFirstSelected
                          value={filterValue.disciplin2}
                          onValueChange={(e) => onChange(e, 'select', 'disciplin2')}
                        />
                      </MDBRow>
                    )}
                    <MDBRow className='pt-2'>
                      <MDBSelect
                        name='kategori'
                        label='Kategori'
                        //noResultLabel="test"
                        // placeholder='Välj förening'
                        data={lov_kategorier}
                        //preventFirstSelected
                        value={filterValue.kategori}
                        onValueChange={(e) => onChange(e, 'select', 'kategori')}
                      // search
                      //multiple
                      />
                    </MDBRow>
                    <MDBRow className='pt-2'>
                      <MDBSelect
                        name='forening'
                        label='Arrangör'
                        //noResultLabel="test"
                        // placeholder='Välj förening'
                        data={lov_foreningar}
                        //preventFirstSelected
                        value={filterValue.forening}
                        onValueChange={(e) => onChange(e, 'select', 'forening')}
                        search
                      //multiple
                      />
                    </MDBRow>
                    <MDBRow className='pt-2'>
                      <MDBSelect
                        name='distrikt'
                        label='Distrikt'
                        //noResultLabel="test"
                        // placeholder='Välj förening'
                        data={lov_distrikt}
                        value={filterValue.distrikt}
                        onValueChange={(e) => onChange(e, 'select', 'distrikt')}
                        //preventFirstSelected
                        search
                      //multiple
                      />
                    </MDBRow>

                    <MDBRow className='px-5 pt-4'>
                      <MDBBtn
                        //color='none'
                        //className='btn-close'
                        onClick={resetFilter}
                      >Nollställ filter</MDBBtn>
                    </MDBRow>
                  </MDBContainer>

                </MDBModalBody>

              </MDBModalContent>
            )}
          </MDBModalDialog>
        </MDBModal>

        <h1>Tävlingskalender</h1>
        <MDBRow className='text-center'>
          <MDBCol md='2'>
            <MDBBtn onClick={toggleOpen} className='m-1 float-start'>Filter</MDBBtn>
            {/*           <MDBBtn onClick={toggleOpen} className='m-1 float-start'>Filter2</MDBBtn>
            <MDBBtn color='primary' onClick={() => setSidenavOpen(!sidenavOpen)} className='m-1 float-start'>
              Filter3
            </MDBBtn> */}
          </MDBCol>
        </MDBRow>





        <MDBRow>
          <MDBAccordion
            alwaysOpen
            active={a_active}
            onChange={(id) => setAccList(id)}
          >
            {loading && !data ? (
              <p>Laddar data...</p>
            ) : (
              <>
                {list(data)}
              </>
            )}

          </MDBAccordion>

        </MDBRow>







      </MDBContainer>

    </>
  );
}

function Modal_more_info(props) {
  //const [fullscreenXlModal, setFullscreenXlModal] = useState(false);

  //const toggleOpen = () => setFullscreenXlModal(!fullscreenXlModal);

  return (
    <>

      <MDBModal tabIndex='-1' open={props.fullscreenXlModal} setOpen={props.setFullscreenXlModal} nonInvasive={false}>
        <MDBModalDialog size="xl">
          <MDBModalContent >
            <MDBModalHeader>
              <MDBModalTitle>Full screen below xl</MDBModalTitle>
              <MDBBtn
                type='button'
                className='btn-close'
                color='none'
                onClick={props.toggleOpen}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              {props.id && (<EventOverviewPageModal {...props}></EventOverviewPageModal>)}

            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn type='button' color='secondary' onClick={props.toggleOpen}>
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}