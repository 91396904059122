import React,{ useMemo, useState, useEffect } from 'react';
import { useLoaderData } from "react-router-dom";
import {    
	MDBCol,
	MDBRow,
	MDBContainer,
	MDBPopover,
	MDBPopoverHeader,
	MDBPopoverBody,
	MDBIcon,
	MDBBadge,
	MDBCardText,
	MDBCard,
	MDBListGroup,
	MDBListGroupItem,
	MDBBtn
} from 'mdb-react-ui-kit';
import {MRT_Localization_SV} from 'material-react-table/locales/sv'
import {
	MaterialReactTable,
	useMaterialReactTable,
	MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
} from 'material-react-table';
import {
	keepPreviousData,
	useQuery,
} from '@tanstack/react-query';
import {
	Box
} from '@mui/material';
import {useNavigate } from "react-router-dom";
import { fetch_get } from '../../../../services/common';

export default function AssociationLicenses() {

	const navigate = useNavigate();
	const loadObject = useLoaderData();
	const association_id = loadObject.association.data.id;
	const sportList = loadObject.sportList.data;
	const [columnFilters, setColumnFilters] = useState([]);
	const [selectedUsers, setSelectedUsers] = useState(null)
	const [selectedRows, setSelectedRows] = useState([])
	const [rowSelection, setRowSelection] = useState({})
  const [globalFilter, setGlobalFilter] = useState('');
	const [loading, setLoading] = useState(false)
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });

	function listSports(sportString){
		const sportArr = sportString.split(',');
		return sportArr.map(sportID => {
			const sportText = sportList.find(({ value }) => value === sportID)
			if(sportText){
				return <MDBCardText key={sportText.value} className="text-muted">{sportText.text}</MDBCardText>
			}
		})
	}

	const tableColumns = useMemo(
    () => [
			{
        accessorKey: 'first_name',
        header: 'Förnamn',
        size: 150,
				Cell: ({renderedCellValue,row}) => (
					<a href={"/user/" + row.original.user_key_id + "/profile"}>
						{renderedCellValue}
					</a>
				)
      },
			{
        accessorKey: 'last_name',
        header: 'Efternamn',
        size: 150,
				Cell: ({renderedCellValue,row}) => (
					<a href={"/user/" + row.original.user_key_id + "/profile"}>
						{renderedCellValue}
					</a>
				)
      },
			{
        accessorKey: 'birth_date',
        header: 'Födelsedatum',
        size: 50,
      },
			{
        accessorKey: 'iid_nr',
        header: 'IID-nr',
        size: 50,
      },
			{
				accessorFn: (originalRow) => (originalRow.skyttekort_grenar !== null ? 'true' : 'false'),
				id: 'skyttekort_grenar',
				header: 'Skyttekort',
				size: 50,
				filterVariant: 'checkbox',
				Cell: ({ cell,row }) => (
					<>
						{cell.getValue() === 'true' ? 
							<MDBPopover
								poperStyle={{width:150}}
								dismiss
								tag='a'
								style={{ cursor: 'pointer'}}
								placement='left'
								btnChildren={<MDBBadge className="mx-2" pill color='success' light><MDBIcon fas icon="check" /></MDBBadge>}
							>
							<MDBPopoverHeader>Grenar</MDBPopoverHeader>
							<MDBPopoverBody>
								{row.original.skyttekort_grenar ? listSports(row.original.skyttekort_grenar) : null}
							</MDBPopoverBody>
							</MDBPopover> : <MDBBadge className="mx-2" pill color='danger' light><MDBIcon fas icon="times" /></MDBBadge>}
					</>
				)
			},
			{
				accessorFn: (originalRow) => (originalRow.active_license === 'true' ? 'true' : 'false'),
				id: 'active_license',
				header: 'Tävlingslicens',
				size: 50,
				filterVariant: 'checkbox',
				Cell: ({ cell,row }) => (
					<>
						{cell.getValue() === 'true' ? 
							<MDBPopover
								poperStyle={{width:150}}
								dismiss
								tag='a'
								style={{ cursor: 'pointer'}}
								placement='left'
								btnChildren={<MDBBadge className="mx-2" pill color='success' light><MDBIcon fas icon="check" /></MDBBadge>}
							>
							<MDBPopoverHeader>Grenar</MDBPopoverHeader>
							<MDBPopoverBody>
								{row.original.skyttekort_grenar ? listSports(row.original.skyttekort_grenar) : null}
							</MDBPopoverBody>
							</MDBPopover> : <MDBBadge className="mx-2" pill color='danger' light><MDBIcon fas icon="times" /></MDBBadge>}
					</>
				)
			},
			{
        accessorKey: 'lic_expired_date',
        header: 'Giltlig tom.',
        size: 50
      }
    ],
    [],
  );

  const {
    data: { data = [], meta } = {},
    isError,
    isRefetching,
    isLoading
  } = useQuery({
    queryKey: [
      'table-data',
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting
    ],
    queryFn: async () => {
      const fetchURL = new URL(
        '/api/data/forening_users_lic_kort/',
        process.env.NODE_ENV === 'production'
          ? 'https://dev.svsf-ta.se'
          : 'https://dev.svsf-ta.se',
      );

      fetchURL.searchParams.set(
        '_skip',
        `${pagination.pageIndex * pagination.pageSize}`,
      );

			function filterFunction(){
				let filterString = "forening_id=" + association_id
				if(globalFilter && globalFilter.length > 0 ){
					filterString = filterString.concat(" AND (first_name LIKE '%" + 
						globalFilter + "%' OR last_name LIKE '%" + 
						globalFilter + "%' OR birth_date LIKE '%" + 
						globalFilter + "%' OR iid_nr LIKE '%" +
						globalFilter + "%')")
				}
				if(columnFilters.length > 0){
					filterString = filterString.concat(" AND (")
					columnFilters.map((col, index) => {
						let searchParam = " LIKE '%" + col.value + "%'"
						if(col.id === "skyttekort_grenar"){
							if(col.value === "true"){
								if(index == 0){
									filterString = filterString.concat(col.id + " IS NOT NULL")
								} else {
									filterString = filterString.concat(" AND " + col.id + " IS NOT NULL")
								}
							} else {
								if(index == 0){
									filterString = filterString.concat(col.id + " IS NULL")
								} else {
									filterString = filterString.concat(" AND " + col.id + " IS NULL")
								}
							}
						} else {
							if(index == 0){
								filterString = filterString.concat(col.id + searchParam)
							} else {
								filterString = filterString.concat(" AND " + col.id + searchParam)
							}
						}
					})
					filterString = filterString.concat(")")
				}
				return filterString
			}
			
      fetchURL.searchParams.set('_top', `${pagination.pageSize}`);
      fetchURL.searchParams.set('_filter', filterFunction());
			if(sorting.length > 0){
				fetchURL.searchParams.set('_orderby', sorting[0].id + " " + (sorting[0].desc ? "desc" : ""));
			}

      const response = await fetch(fetchURL.href);
      const json = await response.json();
      return json;
    },
    placeholderData: keepPreviousData,
  });

  const table = useMaterialReactTable({
    columns: tableColumns,
    data,
    initialState: { 
			showColumnFilters: false, 
			showGlobalFilter: true,
			columnPinning: {
        right: ['mrt-row-actions'],
      },
		},
		/* enableRowActions: true, */
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
		/* renderRowActionMenuItems: ({ row, closeMenu }) => [
			<MDBBtnGroup key={row.id} shadow='0' vertical aria-label='Vertical button group'>
				<MDBBtn onClick={() => (closeMenu(), openSkyttekortModal(row.original))} outline  color='secondary'>Lägg till skyttekort</MDBBtn>
			</MDBBtnGroup>
			,
		], */
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
		enableRowSelection: (row) => row.original.skyttekort_grenar !== null,
		onRowSelectionChange: setRowSelection,
		enableCellActions: true,
    muiSelectCheckboxProps: { color: 'secondary' },
		enableColumnActions: false,
		enableFullScreenToggle: false,
		getRowId: (originalRow) => originalRow.user_key_id,
		localization:MRT_Localization_SV,
    rowCount: meta?.rowcount_total ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
			rowSelection
    },
		renderTopToolbar: ({ table }) => {
			return (
        <Box
          sx={{
						padding: '16px',
            display: 'flex',
            gap: '0.5rem',
            p: '8px',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            <MRT_GlobalFilterTextField table={table} />
            <MRT_ToggleFiltersButton table={table} />
          </Box>
        </Box>

      );
		}
  });

	useEffect(() => {
		setSelectedRows(table.getSelectedRowModel().rows)
		let fetchString = ""
		if(table.getSelectedRowModel().rows.length > 0){
			table.getSelectedRowModel().rows.map((row,index) => {
				const user_key = row.original.user_key_id
				if(index == 0){
					fetchString = "key_id=" + "'" + user_key + "'"
				} else {
					fetchString = fetchString.concat(" OR key_id=" + "'" + user_key + "'")
				}
			})
			setLoading(true)
			fetch_get("data/users_lic/?_filter=" + fetchString)
			.then((data) =>
				setTimeout(() => {
					setSelectedUsers(data.data)
				},
				setLoading(false), 3000)
			);
			
		}
	}, [rowSelection]);

	function listSelectedUsers(){
		if(selectedRows){
			return selectedRows.map(row => {
				return 	<MDBListGroupItem key={row.original.user_key_id}>
									<MDBPopover
										poperStyle={{width:200}}
										dismiss
										tag='a'
										style={{ cursor: 'pointer'}}
										placement='left'
										btnChildren={row.original.user_fullname}
									>
									<MDBPopoverHeader>{row.original.user_fullname}</MDBPopoverHeader>
									<MDBPopoverBody>
										<><b>IID-nr: </b>{row.original.iid_nr}</>
									</MDBPopoverBody>
									</MDBPopover>
								</MDBListGroupItem>
			})
		}
	}



	return (
		<>
			<MDBContainer className="py-4">
				<h3>Medlemmar</h3>
				<hr/>
				<MDBRow>
					<MDBCol size="10" className="mb-3">
						<MaterialReactTable table={table} />
					</MDBCol>
					<MDBCol size="2" className="mb-3">
						<MDBCard>
							<MDBListGroup>
								{listSelectedUsers()}
							</MDBListGroup>
							<MDBBtn disabled={selectedRows.length === 0 || loading ? true : false} onClick={() => {navigate("/step-license",{state: selectedUsers})}}>Köp tävlingslicens</MDBBtn>
						</MDBCard>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		</>
          );
}