import React, { useEffect,useState } from 'react';
import {    
  MDBSelect,
	MDBPopconfirm,
	MDBPopconfirmMessage,
	MDBRow,
	MDBCol,
	MDBModal,
	MDBModalBody,
	MDBModalContent,
	MDBModalTitle,
	MDBModalFooter,
	MDBModalHeader,
	MDBModalDialog,
	MDBBtn,
	MDBAlert,
	MDBSpinner,
	MDBListGroup,
	MDBListGroupItem,
	MDBBadge
 } from 'mdb-react-ui-kit';
 import { fetch_get } from '../../../../services/common';
 import { AddLicense } from '../../license/service/AddLicense';

export default function KansliLicenseModal(params){

	const users = params.sentUsers;
	const [licenseAdded, setLicenseAdded] = useState(false)
	const [licenseRemoved, setLicenseRemoved] = useState(false)

	const addLicense = () => {

		users.map(user => {

				fetch_get("app/user/licenser/" + user.user_key_id)
					.then((data) =>
						setTimeout(() => {
							AddLicense(user.id,getLatestStartDate(data.data), getEndDate(data.data)).then(obj => {
								if(obj.status == "OK"){
									setLicenseAdded(true)
								} else {
									console.log("error")
								}
							});
						}, 3000)
					);

		})
		
	};

	const getLatestStartDate = (userLicenses) => {
		const currentDate = newDate(new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()));
    let latestDate = currentDate
    if(userLicenses != null && userLicenses.length > 0){
      userLicenses.map(license => {
        if(license.status !== "INACTIVE"){
          if(formatDate(latestDate).getTime() < formatDate(license.end_date).getTime()){
            latestDate = license.end_date;
          }
        }
      });
    }
    let date = new Date(latestDate);
    if(latestDate != currentDate){
      date.setDate(date.getDate() + 1);
    }
    return newDate(date)
  }

	const getEndDate = (userLicenses) => {
		let date = new Date(getLatestStartDate(userLicenses))
		date.setFullYear(date.getFullYear() + 1);
		return newDate(date)
	}

	function padDigits(num) {
    return num.toString().padStart(2, '0');
  }
  
  function newDate(date) {
    return [
      date.getFullYear(),
      padDigits(date.getMonth() + 1),
      padDigits(date.getDate()),
    ].join('-');
  }

	function formatDate(date){
    if(date != null){return new Date(date.split("/").reverse().join("-"));}
  }

	return <>
					<MDBAlert
						color='primary'
						autohide
						position='top-right'
						delay={4000}
						appendToBody
						open={licenseAdded}
						onClose={() => setLicenseAdded(false)}
					>
						Licens har lagts till.
					</MDBAlert>
					<MDBAlert
						color='primary'
						autohide
						position='top-right'
						delay={4000}
						appendToBody
						open={licenseRemoved}
						onClose={() => setLicenseRemoved(false)}
					>
						Licens har tagits bort.
					</MDBAlert>
					<MDBModal onClose={params.close} open={params.show} tabIndex='-1'>
        		<MDBModalDialog size="lg">
							<MDBModalContent>
								<MDBModalHeader>
									<MDBModalTitle>Hantera licens</MDBModalTitle>
									<MDBBtn className='btn-close' color='none' onClick={params.close}></MDBBtn>
								</MDBModalHeader>
								<MDBModalBody>
									<MDBRow>
										<MDBCol md={6}>
										<h5>Valda användare</h5>
										<MDBListGroup style={{ minWidth: '12rem' }} light small>												
											{users.map(user => {
												return <MDBListGroupItem className='d-flex justify-content-between align-items-center' key={user.original.user_key_id}>
																<div>
																	<div className='fw-bold'>{user.original.user_fullname}</div>
																</div>
																<MDBBadge pill light color='primary'>
																	IID-nr: {user.original.iid_nr}
																</MDBBadge>
															</MDBListGroupItem>
											})}									
										</MDBListGroup>
										</MDBCol>
										<MDBCol className='text-end'>
											<MDBPopconfirm modal btnChildren='Lägg till licens' onConfirm={() => addLicense()}>
												<MDBPopconfirmMessage>Lägg till 12-månaders licens för valda användare?</MDBPopconfirmMessage>
											</MDBPopconfirm>
										</MDBCol>
									</MDBRow>
							</MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={params.close}>
                Stäng
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
					
        </>
  
}