import {    
  MDBContainer,
  MDBSpinner} from 'mdb-react-ui-kit';
import { add_license, fetch_get, get_order, update_processed_order } from './service/PaymentRequest';
import { useNavigate } from "react-router-dom";
import { fetch_json } from '../../../services/common';
import { useEffect, useState, useRef } from 'react';

async function capturePayment(){
	
  const order_key = localStorage.getItem("order-key");
	const order = await get_order(order_key)
	if(order.data.status == "Complete"){
		return true
	}
  const payment_key = await fetch_json("app/pay/data/" + order_key)
  const obj = await fetch_get(payment_key.data.payment_key,"");
  const metadata = await fetch_get(payment_key.data.payment_key,"metadata");
	
	
	await update_processed_order(order_key,obj.data.paymentOrder.status);
	
  const users = metadata.data.metadata.data;
  if(obj.data.paymentOrder.status == "Paid"){
    users.forEach(async (user) => {
			const resp = await add_license(user);
			if(resp.status !== "OK"){
				return false
			}
		});
		await update_processed_order(order_key,"Complete");
    return true;
  }
  return false;

}

export default function ProcessPayment() {

	const navigate = useNavigate();

	const initialized = useRef(false)

	useEffect(() => {
		if(!initialized.current){
			initialized.current = true
			if(capturePayment()){
				setTimeout(() => {
					navigate("/successful-payment");
				}, 2000)
			}
			else{
				setTimeout(() => {
					navigate("/payment-error");
				}, 2000)
			}
		}
	}, [])
  
      //
   return (
    <MDBContainer className="py-4">
        <div className='d-flex justify-content-center'>
          <MDBSpinner role='status'>
            <span className='visually-hidden'>Loading...</span>
          </MDBSpinner>
        </div>
        <div className='d-flex justify-content-center'>
          Behandlar betalning, stäng inte denna sida.
        </div>
    </MDBContainer>
   );
}