import React, { useEffect,useState } from 'react';
import {    
  MDBSelect,
	MDBPopconfirm,
	MDBPopconfirmMessage,
	MDBRow,
	MDBCol,
	MDBModal,
	MDBModalBody,
	MDBModalContent,
	MDBModalTitle,
	MDBModalFooter,
	MDBModalHeader,
	MDBModalDialog,
	MDBBtn,
	MDBAlert,
	MDBSpinner,
	MDBListGroup,
	MDBListGroupItem,
	MDBBadge
 } from 'mdb-react-ui-kit';
 import { fetch_get } from '../../../../services/common';
 import { AddSkyttekort } from '../../skyttekort/utils/AddSkyttekort';

export default function KansliSkyttekortModal(params){

	const users = params.sentUsers;
	const [skyttekortAdded, setSkyttekortAdded] = useState(false)
	const [skyttekortRemoved, setSkyttekortRemoved] = useState(false)
	const [sportVal, setSportVal] = useState(null)
	const [sportList, setSportList] = useState(null)
	const [loading, setLoading] = useState(true)

	useEffect(() => {
			setLoading(true)
			fetch_get("app/list/lov-grenar/")
			.then((data) =>
				setTimeout(() => {
					setSportList(data.data)
				},
				setLoading(false), 3000)
			);
	}, []);

	const addSkyttekort = () => {

		users.map(user => {

			AddSkyttekort(user.id,sportVal.value,user.original.iid_nr).then(obj => {
				if(obj.status == "OK"){
					setSkyttekortAdded(true)
				} else {
					console.log("error")
				}
			});

		})
		
	};

	return <>
					<MDBAlert
						color='primary'
						autohide
						position='top-right'
						delay={4000}
						appendToBody
						open={skyttekortAdded}
						onClose={() => setSkyttekortAdded(false)}
					>
						Skyttekort har lagts till.
					</MDBAlert>
					<MDBAlert
						color='primary'
						autohide
						position='top-right'
						delay={4000}
						appendToBody
						open={skyttekortRemoved}
						onClose={() => setSkyttekortRemoved(false)}
					>
						Skyttekort har tagits bort.
					</MDBAlert>
					<MDBModal onClose={params.close} open={params.show} tabIndex='-1'>
        		<MDBModalDialog size="lg">
							<MDBModalContent>
								<MDBModalHeader>
									<MDBModalTitle>Hantera skyttekort</MDBModalTitle>
									<MDBBtn className='btn-close' color='none' onClick={params.close}></MDBBtn>
								</MDBModalHeader>
								<MDBModalBody>
								{loading ? 
									<MDBRow className="d-flex justify-content-center py-5">
										<MDBSpinner color='primary'>
											<span className='visually-hidden'>Loading...</span>
										</MDBSpinner>
									</MDBRow> :
									<>
										<MDBRow between>
											<MDBCol md={4}>
												<MDBSelect
													label='Skyttekort'
													data={sportList ? sportList : [{ text: 'Laddar...'}]}
													name="skyttekort"			
													onChange={(val) => setSportVal(val)}
												/>
											</MDBCol>
											<MDBCol md={3} className='d-flex justify-content-end'>
												<MDBPopconfirm disabled={sportVal ? false : true} modal btnChildren='Lägg till skyttekort' onConfirm={() => addSkyttekort()}>
													<MDBPopconfirmMessage>Tilldela skyttekort för <b>{sportVal ? sportVal.text : "???"}</b> till valda användare?</MDBPopconfirmMessage>
												</MDBPopconfirm>
											</MDBCol>
										</MDBRow>
										<br></br>
										<MDBRow>
											<MDBCol md={6}>
											<h5>Valda användare</h5>
											<MDBListGroup style={{ minWidth: '12rem' }} light small>												
												{users.map(user => {
													return <MDBListGroupItem className='d-flex justify-content-between align-items-center' key={user.original.user_key_id}>
																	<div>
																		<div className='fw-bold'>{user.original.user_fullname}</div>
																	</div>
																	<MDBBadge pill light color='primary'>
																		IID-nr: {user.original.iid_nr}
																	</MDBBadge>
																</MDBListGroupItem>
												})}									
											</MDBListGroup>
											</MDBCol>
										</MDBRow>
									</>
								}
							</MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={params.close}>
                Stäng
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
					
        </>
  
}