import { useLoaderData } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
	MDBBtn,
	MDBCard,
	MDBCardBody,
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCardTitle,
	MDBCardText,
	MDBListGroup,
	MDBListGroupItem,
	MDBCollapse
} from "mdb-react-ui-kit";
import { fetch_get } from "../../services/common";
import jwt_decode from "jwt-decode";
import packageJson from '../../../package.json';
import parse from 'html-react-parser';

export const loader = async ({ params }) => {
  const data = localStorage.getItem("token");
  var decoded = "";
  if (data) {
    decoded = jwt_decode(data);
  }

	const obj = {
		user: await fetch_get("app/user/all/" + decoded.kid),
		changelog: await fetch_get("data/changelog")
		}
	return obj;
};

export default function HomePage(){

	const loadObject = useLoaderData();
	const changelog = loadObject.changelog.data;
	const [showChangelog, setShowChangelog] = useState(false)
	const toggleChangelog = () => setShowChangelog(!showChangelog)

	return (
		<>
			<div
				className="bg-image"
				style={{
					backgroundImage: "url('https://mdbcdn.b-cdn.net/img/Photos/Others/images/76.webp')",
					height: "100vh",
				}}
			>
			<MDBContainer className="py-4 p-3 bg-light bg-gradient rounded-5">
				<MDBRow>
					<MDBCol md='8'>
						<MDBCard>
							<MDBCardBody>
								<MDBCardTitle>Nyheter</MDBCardTitle>
								<MDBCardText>
									Nu kan vi börja testa!
								</MDBCardText>
							</MDBCardBody>
						</MDBCard>
					</MDBCol>
					<MDBCol md='4'>
						<MDBCard>
							<MDBCardBody>
								<MDBCardTitle>Changelog</MDBCardTitle>
								<MDBListGroup style={{ minWidth: '22rem' }} light className='mb-3'>
									<MDBListGroupItem>
										<p className='fw-normal'>Aktuell version: <span className='fw-bold'>v{packageJson.version}</span> <span className='fw-light'>(build: {packageJson.build_date})</span></p>
									</MDBListGroupItem>
									{changelog && changelog[0] ? 
										<>
										<MDBListGroupItem >
											<h5 className='fw-bold'>Version {changelog[0].version}</h5>
											<p className='text-muted mb-2 fw-bold'>{changelog[0].date}</p>
											{parse(changelog[0].description)}
										</MDBListGroupItem>
											{changelog.length > 1 ?
												<MDBCollapse open={showChangelog}>
													{changelog.map((log,i) => {
													if(i > 0 && i < 3){
														return <>
																		<hr></hr>
																		<h5 className='fw-bold'>Version {log.version}</h5>
																		<p className='text-muted mb-2 fw-bold'>{log.date}</p>
																		{log.description ? parse(log.description) : null}
																	</>
														}												
													})}
												</MDBCollapse> : null}
										</>
									: null}
									
								</MDBListGroup>
								<MDBBtn onClick={toggleChangelog} outline rounded className='w-100'>
									{showChangelog ? "Dölj" : "Visa äldre"}
								</MDBBtn>
							</MDBCardBody>
						</MDBCard>
					</MDBCol>
				</MDBRow>




			</MDBContainer>
		</div>

	</>
          );
}
