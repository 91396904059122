import { useLoaderData } from "react-router-dom";
import React, { useState} from 'react';
import { fetch_get } from "../../../services/common";
import jwt_decode from "jwt-decode";
import { 
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardImage,
	MDBCardBody,
	MDBTypography,
	MDBCardText,
	MDBPopover,
	MDBPopoverHeader,
	MDBPopoverBody,   
  MDBContainer,
	MDBIcon,
	MDBBtn,
	MDBBadge
 } from 'mdb-react-ui-kit';
import { BorderCol } from "../../modules/BorderCol";
import GetActiveLicense from "../license/utils/GetActiveLicense";
import GetSkyttekortStatus from "../skyttekort/utils/GetSkyttekortStatus";


export async function loader({ params }) {
	const data = localStorage.getItem("token");
	var decoded = "";
	if (data) {
		decoded = jwt_decode(data);
	}

	const obj = {
		user: await fetch_get("app/user/all/" + params.key_id),
		guest: decoded.kid == params.key_id ? false : true
	}
		return obj;
}

export default function UserDetails() {

	const loadObject = useLoaderData();
  const user = loadObject.user.data;
	const [profilePicutre, setProfilePicture] = useState('https://dev.svsf-ta.se/api/app/document/get_document/' + user.profile_picture_link);
	const mailRef = "mailto:" + user.email;
	const activeLicense = GetActiveLicense(user.licenser);
	const activeSkyttekort = GetSkyttekortStatus(user.skyttekort)
	const urlIdx = profilePicutre.lastIndexOf('/');
	const urlEnding = profilePicutre.substring(urlIdx + 1);
	console.log(user)

	if(urlEnding == "null"){
		setProfilePicture('https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png')
	}

	function showRoles(){
		return user.roles.map(role => {
			if(role.id != "100"){
				return true
			}
		})
	}

	function roleName(role){

		if(role.id !== "100"){
			let fullName = role.description;
			if(role.forening_name){
				fullName = fullName.concat(" - ",role.forening_name)
			}
			if(role.distrikt_name){
				fullName = fullName.concat(" - ",role.distrikt_name)
			}
			if(role.gren_name){
				fullName = fullName.concat(" - ",role.gren_name)
			}
	
			return <MDBCardText className="mb-1 text-muted">{fullName}</MDBCardText>
		}

	}

	function listAssociations(){
		const associationArray = []
		user.foreningar.map(forening => {
			if(user.forening_id == forening.forening_id){
				associationArray.unshift(	<div className="text-muted" key={forening.id}>
																		<b>{forening.name} {user.forening_id == forening.forening_id ? "(Huvudförening)" : ""}</b>
																	</div>)
			} else {
				associationArray.push(<div className="text-muted" key={forening.id}>
																{forening.name} {user.forening_id == forening.forening_id ? "(Huvudförening)" : ""}
															</div>)
			}
		})
		return associationArray
	}
  
  return (
		<>
			<MDBContainer className="py-4 p-3 bg-light bg-gradient rounded-5">
				<h3>Min profil</h3>
				<hr/>
				<MDBRow className="justify-content-center align-items-center h-100">
					<MDBCol className="mb-4 mb-lg-0">
						<MDBCard className="mb-3" style={{ borderRadius: '.5rem' }}>
							<MDBRow className="g-0">
								<MDBCol md="2" className="text-center"
									style={{ borderTopLeftRadius: '.5rem', borderBottomLeftRadius: '.5rem' }}>
									<MDBCardImage src={profilePicutre}
										alt="Avatar" className="my-3 rounded-3" style={{ width: '180px',height: '180px', objectFit: 'cover' }} fluid />									
								</MDBCol>
								<MDBCol md="5">
									<MDBCardBody className="p-4">
										<MDBTypography tag="h5">Personuppgifter</MDBTypography>
										<hr className="mt-0 mb-1" />
										<MDBRow className="pt-1">
											<MDBRow>
												<MDBCol md={4}><MDBTypography tag="h6">Namn: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{user.first_name} {user.last_name}</span></MDBCol>
											</MDBRow>
											<MDBRow>
												<MDBCol md={4}><MDBTypography tag="h6">Födelsedatum: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{user.birth_date}</span></MDBCol>
											</MDBRow>
											<MDBRow>
												<MDBCol md={4}><MDBTypography tag="h6">IdrottsID: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{user.licens_nr}</span></MDBCol>
											</MDBRow>
											<MDBRow>
												<MDBCol md={4}><MDBTypography tag="h6">Förening: </MDBTypography></MDBCol>
												<MDBCol>{user.foreningar.length == 0 ? <span> Saknas</span> :
														listAssociations()
													}
												</MDBCol>
											</MDBRow>
											<MDBRow>
												<MDBCol md={4}><MDBTypography tag="h6">E-postadress: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{user.email}</span></MDBCol>
											</MDBRow>
											<MDBRow>
												<MDBCol md={4}><MDBTypography tag="h6">Utländsk: </MDBTypography></MDBCol><MDBCol><span className="text-muted"></span></MDBCol>
											</MDBRow>
											<MDBRow>
												<MDBCol md={4}><MDBTypography tag="h6">Kön: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{user.gender}</span></MDBCol>
											</MDBRow>
											<MDBRow>
												<MDBCol md={4}><MDBTypography tag="h6">Land: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{user.country}</span></MDBCol>
											</MDBRow>
										</MDBRow>
									</MDBCardBody>
								</MDBCol>
								<MDBCol md="5">
									<MDBCardBody className="p-4">
										<MDBTypography tag="h5">Adressuppgifter</MDBTypography>
										<hr className="mt-0 mb-1" />
										<MDBRow className="pt-1">
											<MDBRow>
												<MDBCol md={4}><MDBTypography tag="h6">Adress: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{user.adress1}</span></MDBCol>
											</MDBRow>
											<MDBRow>
												<MDBCol md={4}><MDBTypography tag="h6">Postnummer: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{user.birth_date}</span></MDBCol>
											</MDBRow>
											<MDBRow>
												<MDBCol md={4}><MDBTypography tag="h6">Postort: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{user.city}</span></MDBCol>
											</MDBRow>
											<MDBRow>
												<MDBCol md={4}><MDBTypography tag="h6">Land: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{user.country}</span></MDBCol>
											</MDBRow>
										</MDBRow>
										<MDBTypography className="mt-1" tag="h5">Telefonnummer</MDBTypography>
										<hr className="mt-0 mb-1" />
										<MDBRow className="pt-1">
											<MDBRow>
												<MDBCol md={4}><MDBTypography tag="h6">Mobil: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{user.cell_phone}</span></MDBCol>
											</MDBRow>
											<MDBRow>
												<MDBCol md={4}><MDBTypography tag="h6">Arbete: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{user.phone}</span></MDBCol>
											</MDBRow>
										</MDBRow>
										<MDBTypography className="mt-1" tag="h5">Närmast anhörig/ICE</MDBTypography>
										<hr className="mt-0 mb-1" />
										<MDBRow className="pt-1">
											<MDBRow>
												<MDBCol md={4}><MDBTypography tag="h6">Namn: </MDBTypography></MDBCol><MDBCol><span className="text-muted"></span></MDBCol>
											</MDBRow>
											<MDBRow>
												<MDBCol md={4}><MDBTypography tag="h6">Telefonnummer: </MDBTypography></MDBCol><MDBCol><span className="text-muted"></span></MDBCol>
											</MDBRow>
										</MDBRow>
									</MDBCardBody>
								</MDBCol>
							</MDBRow>
							<MDBRow className="g-0">
								<MDBCol md="2">
									<MDBCardBody className="p-4">
										<MDBTypography tag="h6">Skyttekort {activeSkyttekort ? 
												<MDBBadge className="mx-2" pill color='success' light><MDBIcon fas icon="check" /></MDBBadge> 
												: <MDBBadge className="mx-2" pill color='danger' light><MDBIcon fas icon="times" /></MDBBadge>
											}</MDBTypography>
										<hr className="mt-0 mb-1" />
										<MDBRow className="pt-1">
											<MDBCol md="12" className="mb-3">
												{activeSkyttekort ? user.skyttekort.map(kort => {
													return <MDBCardText key={kort.id} className="my-1 text-muted">{kort.gren_name}</MDBCardText>
												}) : null}
											</MDBCol>
										</MDBRow>
									</MDBCardBody>
								</MDBCol>
								<MDBCol md="5">
									<MDBCardBody className="p-4">
											<MDBTypography tag="h6">Tävlingslicens {activeLicense ? 
												<MDBPopover
													poperStyle={{width:150}}
													dismiss
													tag='a'
													style={{ cursor: 'pointer'}}
													placement='left'
													btnChildren={<MDBBadge className="mx-2" pill color='success' light><MDBIcon fas icon="check" /></MDBBadge>}
												>
													<MDBPopoverHeader>Grenar</MDBPopoverHeader>
													<MDBPopoverBody>
														{activeSkyttekort ? user.skyttekort.map(kort => {
															return <MDBCardText key={kort.id} className="text-muted">{kort.gren_name}</MDBCardText>
														}) : <MDBCardText>Saknas</MDBCardText>}
													</MDBPopoverBody>
													</MDBPopover> : <MDBBadge className="mx-2" pill color='danger' light><MDBIcon fas icon="times" /></MDBBadge>
											}</MDBTypography>
											
										<hr className="mt-0 mb-1" />
										<MDBRow className="pt-1">
											<MDBRow>
												<MDBCol md={4}><MDBTypography tag="h6">Licensnummer: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{user.licens_nr}</span></MDBCol>
											</MDBRow>
											<MDBRow>
												<MDBCol md={4}><MDBTypography tag="h6">Giltlig tom.: </MDBTypography></MDBCol>
												<MDBCol>
													{user.licenser.map(license => {
														if(license.status == "ACTIVE"){
															return <span className="text-muted">{license.end_date}</span>
														}
													})}</MDBCol>
											</MDBRow>
										</MDBRow>
									</MDBCardBody>
								</MDBCol>
								{showRoles() ?
								<MDBCol md="5">
									<MDBCardBody className="p-4">
										<MDBTypography tag="h6">Roller</MDBTypography>
										<hr className="mt-0 mb-1" />
										<MDBRow className="pt-1">
										<MDBCol md="12" className="mb-3">
												{user.roles.length > 0 ? user.roles.map(role => {
													return roleName(role)
												}) : <MDBCardText>Saknas</MDBCardText>}
											</MDBCol>
										</MDBRow>
									</MDBCardBody>
								</MDBCol> : null}
							</MDBRow>
							{/* <MDBRow className="g-0">
								
								<MDBCol md="3">
									<MDBCardBody className="p-4">
										<MDBTypography tag="h6">Utbildningar</MDBTypography>
										<hr className="mt-0 mb-4" />
										<MDBRow className="pt-1">
											<MDBCol md="12" className="mb-3">
											Kommer i senare version
											</MDBCol>
										</MDBRow>
									</MDBCardBody>
								</MDBCol>
								<MDBCol md="4">
									<MDBCardBody className="p-4">
										<MDBTypography tag="h6">Ranking</MDBTypography>
										<hr className="mt-0 mb-4" />
										<MDBRow className="pt-1">
											<MDBCol md="12" className="mb-3">
												Kommer i senare version
											</MDBCol>
										</MDBRow>
									</MDBCardBody>
								</MDBCol>
							</MDBRow> */}
						</MDBCard>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		</>
  );
}