import { useLoaderData,useLocation,useNavigate } from "react-router-dom";
import React, { useState} from 'react';
import { fetch_get } from "../../../services/common";
import {  
	MDBCol,
  MDBContainer,
	MDBListGroup,
	MDBListGroupItem,
	MDBRow,
 } from 'mdb-react-ui-kit';
import jwt_decode from "jwt-decode";
import { BorderCol } from "../../modules/BorderCol";

export async function loader({ params }) {
	const data = localStorage.getItem("token");
	var decoded = "";
	if (data) {
		decoded = jwt_decode(data);
	}

	const obj = {
		user: await fetch_get("app/user/all/" + params.key_id),
		guest: decoded.kid == params.key_id ? false : true
	}
		return obj;
}

export default function UserAssociation() {

	const loadObject = useLoaderData();
  const user = loadObject.user.data;
  
  return (
		<>
			<MDBContainer className="py-4">
				<h3>Föreningar</h3>
				<hr/>
				<MDBRow className='d-flex justify-content-center'>
					<MDBCol md="4">
						{user.foreningar.length == 0 ? "Du är inte med i någon förening." :
							<MDBListGroup style={{ minWidth: '22rem' }} light>
							{user.foreningar.map(forening => {
								console.log(forening)
								return <MDBListGroupItem key={forening.id} className='d-flex justify-content-between align-items-center'>
									{forening.name} {user.forening_id == forening.forening_id ? "(Huvudförening)" : ""}
								</MDBListGroupItem>
							})}
							</MDBListGroup>
						}
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		</>
  );
}