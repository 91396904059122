import React, { useState, useRef, useEffect, useMemo } from 'react';
import { MDBFilters } from 'mdb-react-filters';
import {
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBSideNavCollapse,
  MDBIcon,
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCheckbox,
  MDBCol,
  MDBDatepicker,
  MDBSelect,
  MDBInputGroup,
  MDBRadio,
  MDBInput,
  MDBValidationItem,
  MDBValidation,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardHeader,
  MDBAccordion,
  MDBAccordionItem,
  MDBCardSubTitle,
  MDBDatatable,
  MDBBadge,
  MDBModal,
  MDBModalHeader,
  MDBModalDialog,
  MDBModalContent,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBTextArea,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBDropdown,
  MDBDropdownToggle,
  MDBTooltip,

} from 'mdb-react-ui-kit';
import {
  Outlet,
  NavLink,
  Link,
  useLoaderData,
  useLocation,
  Form,
  redirect,
  useNavigate,
  useParams,
  //useNavigation,
  //useSubmit,
} from "react-router-dom";
import { MDBTreeview, MDBTreeviewItem } from 'mdb-react-treeview';
import Icon_Svsf from "../../svg/svg_icon_svsf";
import Sidenav from "../../modules/Sidenav_Event";
import LovForeningar from "../../app/lov/LovForeningar";
import { fetch_get, fetch_post } from "../../../services/common";




// export const loader = async ({ params }) => {
//   //const query = useQuery();
//   console.log('loader eventNew', params.id);
//   const resJson = await fetch_get('app/event/form_data/' + params.id);
//   //const resJson = await res.json();
//   console.log('loader eventNew');
//   return resJson;
// };

const customWeekdaysNarrow = ['S', 'M', 'T', 'O', 'T', 'F', 'L'];
const customMonthsFull = ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December',];
const customWeekdaysShort = ['Sön', 'Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör'];
const customMonthsShort = ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export async function action({ request, params }) {
  //const contact = await createContact();
  console.log('action....');
  const formData = await request.formData();
  const updates = Object.fromEntries(formData);
  console.log(updates);
  console.log('title', formData.get('title'));
  //return redirect('/event');
  return [];
}


export default function EventsNew({ formRef }) {
  const { id } = useParams();
  const loadObject = useLoaderData();
  const [data, setData] = useState(loadObject);
  const [lovDataTypes, setLovTypesData] = useState([]);
  const [lovDataKategorier, setLovKategorierData] = useState([]);
  const [lovloading, setLovLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const [basicOpen, setBasicOpen] = useState(true);
  const [sidenavOpen, setSidenavOpen] = useState(false);
  const [basicCollapse1, setBasicCollapse1] = useState(true);
  const [basicCollapse2, setBasicCollapse2] = useState(true);
  const contentRef = useRef(null);

  const navigate = useNavigate();

  const [basicModal, setBasicModal] = useState(false);
  const toggleShow = () => setBasicModal(!basicModal);

  const [sportgrenarModal, setSportgrenarModal] = useState(false);
  const [treeClosed, setTreeClosed] = useState(true);
  const toggleSportgrenarOpen = () => setSportgrenarModal(!sportgrenarModal);


  const [label, setLabel] = useState({ rubrik: "Tävling" });

  const query = useQuery();

  const [formValue, setFormValue] = useState({
    arrangorer: [],
    sportgrenar: [],
    action: 'event_add_edit',
    key_id: id,
  });


  useEffect(() => {


    fetch_get('app/event/form_data/' + id)
      .then(data => {
        console.log('aaa', data);
        setFormValue({
          action: 'event_add_edit',
          key_id: data.data.key_id,
          db_name: data.data.name,
          db_parent_id: data.data.parent_id,
          db_event_type_id: data.data.event_type_id,
          db_kategori_id: data.data.kategori_id,
          db_kontakt_first_name: data.data.kontakt_first_name,
          db_kontakt_email: data.data.kontakt_email,
          db_kontakt_phone: data.data.kontakt_phone,
          db_start_date: data.data.start_date,
          db_end_date: data.data.end_date,
          db_publicering_date: data.data.publicering_date,
          db_anmalan_start_date: data.data.anmalan_start_date,
          db_anmalan_end_date: data.data.anmalan_end_date,
          db_efteranmalan: false,
          db_efteranmalan_end_date: data.data.efteranmalan_end_date,
          db_plats: data.data.plats,
          db_hemsida: data.data.hemsida,
          arrangorer: data.data.arrangorer,
          sportgrenar: data.data.sportgrenar,
        });

        if (data.data.event_type_id == '-1') {
          console.log('Evenemang');
          setLabel({ rubrik: "Evenemang" });
        }

      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLovLoading(false);
      });

    fetch_get('data/event-lovs')
      .then(data => {


        setLovTypesData(data.data[0]["lov-event-types"]);
        setLovKategorierData(data.data[0]["lov-kategorier"]);
        setLovLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLovLoading(false);
      });
  }, []);

  const onSubmitEvent = async (e) => {
    e.preventDefault();

    if (e.target.checkValidity()) {
      console.log('handleSubmit', formValue);
      const resJson = await fetch_post('app/event/do_submit/' + formValue.key_id, formValue);

      navigate("/event/" + resJson.key_id);
    } else {
      console.log('form not valid');
    }

  };

  const handleCancel = async (e) => {
    e.preventDefault();

    if (formValue.key_id) {
      navigate("/event/" + formValue.key_id);
    } else {
      navigate("/events/");
    }

  };

  const openModal = async (e) => {
    e.preventDefault();
    console.log('sdfsdf');
    toggleSportgrenarOpen();
  };




  //const [formValue, setFormValue] = useState(data.data);




  const arrangorerData = {

    columns: [{ label: '', field: 'actions', sort: false },
      // { label: '', field: 'id', sort: false },
      // { label: '', field: 'forening', sort: false },
    ],
    rows: formValue.arrangorer.map((row, i) => {
      return {
        ...row,
        actions: (
          <>
            <a
              role='button'
              title='Ta bort arrangören'
              onClick={() => removeArrangor(i)}
              className='delete-email-button text-muted ms-2'
            >
              <MDBIcon icon='trash-alt' className='datatable-disable-onclick px-2' />
            </a>
            <span>{row.name}</span>

          </>
        ),
      };
    }),
  };

  const sportgrenData = {

    columns: [{ label: '', field: 'actions', sort: false },
      // { label: '', field: 'id', sort: false },
      // { label: '', field: 'forening', sort: false },
    ],
    rows: formValue.sportgrenar.map((row, i) => {
      return {
        ...row,
        actions: (
          <>
            <a
              role='button'
              title='Ta bort sportgren'
              onClick={() => removeSportgren(i)}
              className='delete-email-button text-muted ms-2'
            >
              <MDBIcon icon='trash-alt' className='datatable-disable-onclick px-2' />
            </a>
            <span>{row.sportgren}</span>

          </>
        ),
      };
    }),
  };

  const removeArrangor = (pos) => {
    var arr = formValue.arrangorer;
    arr.splice(pos, 1);
    setFormValue({ ...formValue, arrangorer: arr });

  };

  const removeSportgren = (pos) => {
    console.log(pos);
    var arr = formValue.sportgrenar;
    arr.splice(pos, 1);
    setFormValue({ ...formValue, sportgrenar: arr });

  };  

  const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
    console.log(formValue);
  };

  const onChangeStartDate = async (date) => {
    console.log('db_start_date', date);
    let l_end_date = date;
    if (formValue.db_end_date) {
      l_end_date = formValue.db_end_date;
      if (l_end_date < date) {
        l_end_date = date;
      }
    }

    setFormValue({ ...formValue, 'db_start_date': date, 'db_end_date': l_end_date });


    console.log('db_start_date', formValue);
  };

  const onChangeEndDate = (date) => {
    console.log('db_end_date', date);
    setFormValue({ ...formValue, 'db_end_date': date });
    console.log('db_end_date', formValue);
  };

  const onChange_db_anmalan_start_date = (date) => {
    console.log('db_anmalan_start_date', date);
    setFormValue({ ...formValue, 'db_anmalan_start_date': date });
    console.log('db_anmalan_start_date', formValue);
  };

  const onChange_db_anmalan_end_date = (date) => {
    console.log('db_anmalan_end_date', date);
    setFormValue({ ...formValue, 'db_anmalan_end_date': date });
    console.log('db_anmalan_end_date', formValue);
  };

  const onChangePublDate = (date) => {
    console.log('db_publicering_date', date);
    setFormValue({ ...formValue, 'db_publicering_date': date });
    console.log('db_publicering_date', formValue);
  };

  const setMinDate = (date_in) => {
    var date = new Date(date_in);
    date.setDate(date.getDate() - 1);
    return date;
  };

  const callbackFunctionAddArrangorer = (childData) => {

    var arr = formValue.arrangorer;
    arr.push({ forening_id: childData.value, name: childData.text });
    setFormValue({ ...formValue, arrangorer: arr });
    console.log(childData);
    console.log(arr);

    //this.setState({message: childData})

  }

  const addsportgren = (id, child_text) => {

    console.log('callback - add sportgren', id, child_text);
    var arr = formValue.sportgrenar;
    arr.push({ sportgren_id: id, sportgren: child_text });
    setFormValue({ ...formValue, sportgrenar: arr });
   // console.log(childData);
    console.log(arr);

    //this.setState({message: childData})

  }

  const style = {
    //transform: translateY(-1 +'rem')
    transform: 'rotate(180deg)'
  }

  const mainStyles = {
    rubriker: {
      height: 20,
      position: 'absolute',
      top: '-10px',

    },
  };

  return (
    <>
      <header>
        {/* <Sidenav basicOpen={basicOpen} setBasicOpen={setBasicOpen} /> */}
      </header>


      <MDBContainer className="py-4">

        {/* <h1>Ny/ändra tävling</h1> */}


        <MDBValidation onSubmit={onSubmitEvent} ref={formRef}>
          <div className='col-12'>
            <MDBBtn type='submit' className='me-2'>Spara</MDBBtn>
            <MDBBtn type='submit' className='me-2' color='secondary' onClick={handleCancel}>Avbryt</MDBBtn>
          </div>
          <MDBRow className='g-3 py-3'>
            <MDBCol className='col-md-8 col-sm-12'>
              <MDBRow className='g-3'>
                <MDBCard border='primary' className='p-2'>

                  <MDBCardBody>
                    <MDBBadge light style={mainStyles.rubriker}>{label.rubrik}</MDBBadge>
                    <MDBRow>
                      <MDBValidationItem feedback='Ange ett namn på tävlingen.' invalid className='col-md-12 col-lg-10 py-2'>
                        <MDBInput
                          name='db_name'
                          required
                          label={<>{label.rubrik} <strong className='text-muted'>*</strong></>}
                          id='db_name'
                          type='text'
                          onChange={onChange}
                          value={formValue.db_name} />
                      </MDBValidationItem>
                      {formValue.db_event_type_id != '-1' && (
                        <>
                          <MDBValidationItem feedback='Ange tävlingstyp.' invalid className='col-md-5 col-lg-5 py-2'>
                            <MDBSelect
                              name='db_event_type_id'
                              label='Typ av tävling'
                              preventFirstSelection
                              onValueChange={(e) => setFormValue({ ...formValue, 'db_event_type_id': e.value })}
                              data={lovDataTypes}
                              value={formValue.db_event_type_id}
                            />

                          </MDBValidationItem>
                          <MDBValidationItem feedback='Ange kategori.' invalid className='col-md-5 col-lg-5 py-2'>

                            <MDBSelect
                              name='db_kategori_id'
                              label='Kategori'
                              preventFirstSelection
                              // value={formValue.db_kategori_id}
                              onValueChange={(e) => setFormValue({ ...formValue, 'db_kategori_id': e.value })}
                              data={lovDataKategorier}
                              value={formValue.db_kategori_id}
                            />

                          </MDBValidationItem>
                        </>
                      )}
                    </MDBRow>
                    <MDBRow>
                      <MDBValidationItem feedback='Ange start datum för tävlingen.' invalid className='col-md-6 col-lg-4 py-2'>
                        <MDBDatepicker
                          name='db_start_date'
                          disablePast
                          required
                          inputLabel={<>Start datum <strong className='text-muted'>*</strong></>}
                          format='yyyy-mm-dd'
                          startDay={1}
                          onChange={onChangeStartDate}
                          defaultValue={formValue.db_start_date}
                          value={formValue.db_start_date}
                          weekdaysNarrow={customWeekdaysNarrow}
                          monthsFull={customMonthsFull}
                          weekdaysShort={customWeekdaysShort}
                          monthsShort={customMonthsShort}
                          title='Välj datum'
                          cancelBtnText='Avbryt'
                          clearBtnText='Reset'
                          inline
                        />
                      </MDBValidationItem>
                      <MDBValidationItem feedback='Ange Slut datum för tävlingen.' invalid className='col-md-6 col-lg-4 py-2'>
                        <MDBDatepicker
                          name='db_end_date'
                          disablePast
                          required
                          inputLabel={<>Slut datum <strong className='text-muted'>*</strong></>}
                          format='yyyy-mm-dd'
                          startDay={1}
                          onChange={onChangeEndDate}
                          defaultValue={formValue.db_end_date}
                          value={formValue.db_end_date}
                          min={setMinDate(formValue.db_start_date)}
                          monthsFull={customMonthsFull}
                          weekdaysShort={customWeekdaysShort}
                          monthsShort={customMonthsShort}
                          title='Välj datum'
                          cancelBtnText='Avbryt'
                          clearBtnText='Reset'
                          inline

                        />
                      </MDBValidationItem>
                    </MDBRow>
                    <MDBRow>
                      <MDBValidationItem feedback='Ange önskat publicerings datum för tävlingen.' invalid className='col-md-6 col-lg-4 py-2'>
                        <MDBDatepicker
                          name='db_publicering_date'
                          disablePast
                          required
                          inputLabel={<>Publicerings datum <strong className='text-muted'>*</strong></>}
                          format='yyyy-mm-dd'
                          startDay={1}
                          onChange={onChangePublDate}
                          defaultValue={formValue.db_publicering_date}
                          value={formValue.db_publicering_date}
                          //min={setMinDate(formValue.start_date)}
                          monthsFull={customMonthsFull}
                          weekdaysShort={customWeekdaysShort}
                          monthsShort={customMonthsShort}
                          title='Välj datum'
                          cancelBtnText='Avbryt'
                          clearBtnText='Reset'
                          inline

                        />
                      </MDBValidationItem>

                    </MDBRow>

                    <MDBRow>
                      <MDBValidationItem feedback='-' invalid className='col-md-6 col-lg-4 py-2'>
                        <MDBDatepicker
                          name='db_anmalan_start_date'
                          disablePast
                          //required
                          inputLabel={<>Öppna för anmälan</>}
                          format='yyyy-mm-dd'
                          startDay={1}
                          onChange={onChange_db_anmalan_start_date}
                          defaultValue={formValue.db_anmalan_start_date}
                          value={formValue.db_anmalan_start_date}
                          //min={setMinDate(formValue.start_date)}
                          monthsFull={customMonthsFull}
                          weekdaysShort={customWeekdaysShort}
                          monthsShort={customMonthsShort}
                          title='Välj datum'
                          cancelBtnText='Avbryt'
                          clearBtnText='Reset'
                          inline

                        />
                      </MDBValidationItem>

                      <MDBValidationItem feedback='-' invalid className='col-md-6 col-lg-4 py-2'>
                        <MDBDatepicker
                          name='db_anmalan_end_date'
                          disablePast
                          //required
                          inputLabel={<>Sista anmälningsdag</>}
                          format='yyyy-mm-dd'
                          startDay={1}
                          onChange={onChange_db_anmalan_end_date}
                          defaultValue={formValue.db_anmalan_end_date}
                          value={formValue.db_anmalan_end_date}
                          //min={setMinDate(formValue.start_date)}
                          monthsFull={customMonthsFull}
                          weekdaysShort={customWeekdaysShort}
                          monthsShort={customMonthsShort}
                          title='Välj datum'
                          cancelBtnText='Avbryt'
                          clearBtnText='Reset'
                          inline

                        />
                      </MDBValidationItem>


                    </MDBRow>


                    <MDBRow>
                      {/* <MDBBtn className="col-md-3" tag="a" onClick={toggleShow}>Lägg till arrangör</MDBBtn> */}
                      <MDBCol className="col-md-5  py-2">
                        <LovForeningar parentCallback={callbackFunctionAddArrangorer} />
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>

                      <MDBDatatable
                        data={arrangorerData}
                        noFoundMessage='Ingen arrangör vald!'
                        pagination={false}
                        maxWidth='260px'
                        sm
                        borderless
                        isLoading={loading}
                      />
                      {/* <MDBDataTable striped bordered scrollY hover entriesOptions={[5, 20, 25]} entries={5} pagesAmount={4} data={yourData} /> */}


                    </MDBRow>
                  </MDBCardBody>
                </MDBCard>

                <MDBCard border='primary' className='p-2'>
                  {/* <MDBCardHeader className="position-absolute top-0 start-0 translate-middle px-6"> Hjälptexter</MDBCardHeader> */}
                  <MDBCardBody>
                    {/* <MDBCardTitle>Special title treatment</MDBCardTitle> */}
                    <MDBBadge light style={mainStyles.rubriker}>Sportgrenar</MDBBadge>

                    <MDBRow>
                    <div className="d-grid gap-2 d-md-block">
                      <MDBBtn color='secondary' onClick={openModal} size='sm' className='ms-3'><MDBIcon fas icon="plus" className='me-2'/>Lägg till Sportgren</MDBBtn>
                      </div>
                      <MDBDatatable
                        data={sportgrenData}
                        noFoundMessage='Ingen sportgren vald!'
                        pagination={false}
                        maxWidth='500px'
                        sm
                        borderless
                        isLoading={loading}
                      />                      

                    </MDBRow>
                  </MDBCardBody>
                </MDBCard>

                <MDBCard border='primary' className='p-2'>
                  {/* <MDBCardHeader className="position-absolute top-0 start-0 translate-middle px-6"> Hjälptexter</MDBCardHeader> */}
                  <MDBCardBody>
                    {/* <MDBCardTitle>Special title treatment</MDBCardTitle> */}
                    <MDBBadge light style={mainStyles.rubriker}>Plats</MDBBadge>

                    <MDBRow>

                      <MDBValidationItem className='py-2 col-md-8  col-lg-6' invalid feedback='Ange en plats tävlingen.'>
                        <MDBTextArea
                          name='db_plats'
                          id='form_db_plats'
                          label={<>Plats för evenemanget <strong className='text-muted'>*</strong></>}
                          value={formValue.db_plats || ""}
                          onChange={onChange}
                          required
                          rows={2}
                        />
                      </MDBValidationItem>
                      <MDBValidationItem className='py-2 col-md-8  col-lg-6' invalid feedback='Ange en giltig hemsida (http://www.ex.se  eller  https://www.ex.se ).'>
                        <MDBInput
                          name='db_hemsida'
                          id='form_db_hemsida'
                          label='Arrangörens hemsida'
                          value={formValue.db_hemsida}
                          onChange={onChange}
                          type='url'
                          placeholder="http://  eller  https://"
                        />

                      </MDBValidationItem>




                    </MDBRow>
                  </MDBCardBody>
                </MDBCard>

                <MDBCard border='primary' className='p-2'>
                  {/* <MDBCardHeader>Hjälptexter</MDBCardHeader> */}
                  <MDBCardBody>
                    {/* <MDBCardTitle>Special title treatment</MDBCardTitle> */}
                    <MDBBadge light style={mainStyles.rubriker}>Kontaktuppgifter</MDBBadge>
                    <MDBRow>
                      <MDBValidationItem className='py-2 col-md-6  col-lg-4' invalid feedback='Ange en kontaktperson till tävlingen.'>
                        <MDBInput
                          name='db_kontakt_first_name'
                          id='form_db_kontakt_first_name'
                          label={<>Namn <strong className='text-muted'>*</strong></>}
                          value={formValue.db_kontakt_first_name}
                          onChange={onChange}
                          required
                        />
                      </MDBValidationItem>
                      <MDBValidationItem className='py-2 col-md-6  col-lg-4' invalid feedback='Ange en giltig e-post adress.'>
                        <MDBInput
                          name='db_kontakt_email'
                          id='form_db_kontakt_email'
                          label={<>Epost <strong className='text-muted'>*</strong></>}
                          type='email'
                          value={formValue.db_kontakt_email}
                          onChange={onChange}
                          required
                        />
                      </MDBValidationItem>
                      <MDBValidationItem className='py-2 col-md-6  col-lg-4' invalid feedback='Ange telefonnummer.'>
                        <MDBInput
                          name='db_kontakt_phone'
                          id='form_db_kontakt_phone'
                          label={<>Telefonnummer <strong className='text-muted'>*</strong></>}
                          type='text'
                          value={formValue.db_kontakt_phone}
                          onChange={onChange}
                          required
                        />
                      </MDBValidationItem>
                    </MDBRow>
                  </MDBCardBody>
                </MDBCard>


              </MDBRow>

            </MDBCol>

          </MDBRow>


          <MDBRow className='gy-3'>



            <div className='col-12'>
              <MDBBtn type='submit' className='me-2'>Spara</MDBBtn>
              <MDBBtn type='submit' className='me-2' color='secondary' onClick={handleCancel}>Avbryt</MDBBtn>
            </div>

          </MDBRow>
        </MDBValidation>

      </MDBContainer >

      <EventModalSportgrenar
        sportgrenarModal={sportgrenarModal}
        setSportgrenarModal={setSportgrenarModal}
        toggleSportgrenarOpen={toggleSportgrenarOpen}
        addsportgren={addsportgren}
      // treeClosed={treeClosed}
      />



    </>
  );
}

// sportgrenarModal, setSportgrenarModal
function EventModalSportgrenar(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [treeClosed, setTreeClosed] = useState(true);

  useEffect(() => {

    fetch_get('app/list/sportgrenar-tree')
      .then(data => {
        setData(data.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, [loading]);

  const dropDownMenu = (row) => {
    return
    <MDBBtn>Button</MDBBtn>
    // <MDBDropdown style={{float: 'right'}}> 
    // 				<MDBDropdownToggle size='sm' style={{background: 'transparent', boxShadow: 'none'}}><MDBIcon style={{ color: '#0065A4'}} fas icon="ellipsis-h" /></MDBDropdownToggle>
    // 				<MDBDropdownMenu className='align-items-center' style={{padding: 0, margin: 0}}>
    // 					<MDBDropdownItem link childTag='button' onClick={e => console.log(row.value)}>
    // 						<h6>Lägg till klass</h6>
    // 					</MDBDropdownItem>
    // 					<MDBDropdownItem link childTag='button'>
    // 					<h6>Ändra namn</h6>
    // 					</MDBDropdownItem>
    // 					<MDBDropdownItem divider />
    // 					<MDBDropdownItem link childTag='button'>
    // 					<h6 className='text-danger'>Ta bort</h6>
    // 					</MDBDropdownItem>
    // 				</MDBDropdownMenu>
    // 			</MDBDropdown>
  }

  const handleClick = (e, i, child_text) => {
    console.log('click', i, child_text);
    props.addsportgren(i, child_text);
    props.toggleSportgrenarOpen();
  };

  // const handleCancel = async (e) => {
  //   e.preventDefault();

  //   if (formValue.key_id) {
  //     navigate("/event/" + formValue.key_id);
  //   } else {
  //     navigate("/events/");
  //   }

  // };  

  const treeLoop = (row) => {
    if (row.children) {
      return <MDBTreeviewItem
        show
        key={row.value}
        subtree
        icon='caret-down' //'angle-double-down'
        rotationAngle={-90}
        name={row.name}>

        {row.children.map(child => {
          if (child.children) {
            return treeLoop(child);
          } else {
            return <MDBTreeviewItem key={child.value} name={
              <div className='d-flex justify-content-between w-100'>
                <div>{child.name}</div>
                <div>
                  <MDBBtn color='secondary' size='sm' title='Lägg till sportgren'
                    onClick={(e) => handleClick(e, child.value, child.tree_text)}
                    id={200}
                  >
                    <MDBIcon fas icon='angle-double-right' />
                  </MDBBtn>
                </div>
              </div>
            }
              id={child.name + "-" + child.value} value={child.value}
            />
          }
        })}
      </MDBTreeviewItem>
    } else {
      return <MDBTreeviewItem key={row.value} name={row.name} />
    }
  }


  // const treeLoop = (row) => {
  // 	if(row.children){
  // 		return <MDBTreeviewItem 
  //     show={true}
  //     key={row.value} 
  //      subtree 
  //     name={<>{row.name}</>} id={row.name + "-" + row.value} value={row.value}>
  // 							{row.children.map(child => {
  // 								if(child.children){
  // 									return treeLoop(child);
  // 								} else {
  // 									return	<MDBTreeviewItem key={child.value} name={<div  className='d-flex justify-content-between w-100'><div>{child.name}</div>
  //                   <div>
  //                   <MDBTooltip

  //         wrapperProps={{ tag: 'a', size: 'sm', className: 'call-btn me-2 mb-2', onClick: () => onaddsportgrene(row.original.id, row.original.full_name) }}
  //         title="Lägg till sportgren"

  //       >
  //         <MDBIcon fas icon='angle-double-right' />
  //       </MDBTooltip>
  //                     </div>
  //                   </div>
  //                   } id={child.name + "-" + child.value} value={child.value}/>
  // 								}
  // 							})}
  // 						</MDBTreeviewItem>
  // 	} else {
  // 		return <MDBTreeviewItem key={row.value} name={<div className='d-flex justify-content-between w-100'><div>{row.name}</div></div>} id={row.name + "-" + row.value} value={row.value}/>
  // 	}
  // }  

  return (
    <>

      <MDBModal tabIndex='-1' open={props.sportgrenarModal} setOpen={props.setSportgrenarModal} nonInvasive={false} >
        <MDBModalDialog >
          <MDBModalContent >
            <MDBModalHeader>
              <MDBModalTitle>Ange sportgren</MDBModalTitle>
              <MDBBtn
                type='button'
                className='btn-close'
                color='none'
                onClick={props.toggleSportgrenarOpen}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>



              <MDBTreeview line openOnItemClick={true}/* onClick={rowClick} */>
                {data.map(row => {
                  return treeLoop(row)
                })}
              </MDBTreeview>



              {/* <MDBTreeview selectable>
                <MDBTreeviewItem name='One' selectable={false} />
                <MDBTreeviewItem name='Two' selectable={true} />
                <MDBTreeviewItem subtree show name='Three' icon='angle-double-down' rotationAngle={-90} >
                  <MDBTreeviewItem name='Second-one' />
                  <MDBTreeviewItem name='Second-two' />
                  <MDBTreeviewItem name='Second-three' subtree icon='angle-double-right' rotationAngle={180}>
                    <MDBTreeviewItem name='Third-one' subtree icon='angle-double-right' rotationAngle={180}>
                      <MDBTreeviewItem name='Fourth-one' />
                      <MDBTreeviewItem name='Fourth-two' />
                      <MDBTreeviewItem name='Fourth-three' />
                    </MDBTreeviewItem>
                    <MDBTreeviewItem name='Third-two' />
                    <MDBTreeviewItem name='Third-three' subtree icon='angle-double-right' rotationAngle={180}>
                      <MDBTreeviewItem name='Fourth-one' selectable />
                      <MDBTreeviewItem name='Fourth-two' selectable />
                      <MDBTreeviewItem name='Fourth-three' selectable />
                    </MDBTreeviewItem>
                  </MDBTreeviewItem>
                </MDBTreeviewItem>
              </MDBTreeview> */}

              {/* <MDBDropdown>
                <MDBDropdownToggle>Dropdown button</MDBDropdownToggle>
                <MDBDropdownMenu>
                  <MDBDropdownItem>
                    <a href="#">Action</a>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <a href="#">Another action</a>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <a href="#">Submenu &raquo;</a>
                    <ul className="dropdown-menu dropdown-submenu">
                      <MDBDropdownItem>
                        <a href="#">Submenu item 1</a>
                      </MDBDropdownItem>
                      <MDBDropdownItem>
                        <a href="#">Submenu item 2</a>
                      </MDBDropdownItem>
                      <MDBDropdownItem>
                        <a href="#">Submenu item 3 &raquo;</a>
                        <ul className="dropdown-menu dropdown-submenu">
                          <MDBDropdownItem>
                            <a href="#">Multi level 1</a>
                          </MDBDropdownItem>
                          <MDBDropdownItem>
                            <a href="#">Multi level 2</a>
                          </MDBDropdownItem>
                        </ul>
                      </MDBDropdownItem>
                      <MDBDropdownItem>
                        <a href="#">Submenu item 4</a>
                      </MDBDropdownItem>
                      <MDBDropdownItem>
                        <a href="#">Submenu item 5</a>
                      </MDBDropdownItem>
                    </ul>
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown> */}

            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn type='button' color='secondary' onClick={props.toggleSportgrenarOpen}>
                Stäng
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}