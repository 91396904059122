import React, { useState, useRef, useEffect, useMemo } from 'react';
import { MRT_Localization_SV } from 'material-react-table/locales/sv'
import {
  MRT_Table,
  MaterialReactTable,
  useMaterialReactTable,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton,
} from 'material-react-table';
import {
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBSideNavCollapse,
  MDBIcon,
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCheckbox,
  MDBCol,
  MDBDatepicker,
  MDBSelect,
  MDBInputGroup,
  MDBRadio,
  MDBInput,
  MDBValidationItem,
  MDBValidation,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardHeader,
  MDBAccordion,
  MDBAccordionItem,
  MDBCardSubTitle,
  MDBDatatable,
  MDBBadge,
  MDBModal,
  MDBModalHeader,
  MDBModalDialog,
  MDBModalContent,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBTextArea,
  MDBSwitch,
  MDBPopconfirm,
  MDBPopconfirmMessage,
  MDBNavbar,
  MDBTooltip,
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBDropdownToggle,
  MDBTable,
  MDBListGroup,
  MDBListGroupItem,
  MDBTableHead,
  MDBTableBody,
  MDBBtnGroup

} from 'mdb-react-ui-kit';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import PrintIcon from '@mui/icons-material/Print';
import { useParams, NavLink } from "react-router-dom";
import { fetch_get, } from "../../../services/common";
import AuthService from "../../../services/auth.service";
import { EventRubrik } from "./Event_common";

export async function loader() {

  return [];
}
import { BorderHeadline } from '../../modules/BorderHeadline';
import { BorderCol } from '../../modules/BorderCol';
import { TRUE } from 'sass';

export default function EventAnmalan({ props }) {
  const { id } = useParams();
  const [teams, setTeams] = useState([]);
  const [filter, setFilter] = useState([]);
  const [activefilter, setAktivFilter] = useState(0);
  const [loading, setLoading] = useState(true);
  const [columnFilters, setcolumnFilters] = useState([]);


  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };



  useEffect(() => {

    fetch_get('app/event/teams/' + id)
      .then(data => {
        setTeams(data.data);
        setFilter(data.filter);

        // setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });


  }, [loading]);

  const onnew = async () => {

    console.log('new');
    // setFormValue(
    //   {
    //     action: 'event_team_config',
    //     key_id: id,
    //     team_id: 0,
    //     db_def_event_team_type_id: "",
    //     db_name: "",
    //     db_number_of_team_members: "",
    //     db_startavgift: "",
    //     klasser: []
    //   });

    // setBasicModal(true);

  };


  const onChangeFilter = async (f) => {

    console.log('filter', f);
    setAktivFilter(f);
    if (f === 0) {
      setcolumnFilters([]);
    } else {
      setcolumnFilters([{ id: 'event_team_config_id', value: f }]);
    }

  };

  const tableColumns = useMemo(
    () => [
      {
        accessorKey: 'event_team_config_id', //access nested data with dot notation
        header: 'event_team_config_id',
        size: 50,
        enableEditing: false,
        filterFn: 'equals',
      },
      {
        accessorKey: 'team_config_name', //access nested data with dot notation
        header: 'Lagtävling',
        size: 50,
        enableEditing: false,
        filterFn: 'equalsString',
      },
      {
        accessorFn: (row) => `${row.name} ${row.team_suffix}`, //access nested data with dot notation
        header: 'Namn på laget',
        // id: 'lag_namn',
        size: 150,
        enableEditing: false,
      },
      {
        accessorFn: (row) => `${row.members.length} (${row.number_of_team_members})`, //access nested data with dot notation
        header: 'Antal i laget',
        size: 50,
        enableEditing: false,
      },
      {
        accessorKey: 'name', //access nested data with dot notation
        header: 'Förening',
        size: 150,
        enableEditing: false,
      },

    ],
    [],
  );


  const table = useMaterialReactTable({
    columns: tableColumns,
    data: teams,
    //enableEditing: true,
    enablePagination: false,
    enableColumnActions: false,
    enableRowActions: true,
    enableStickyHeader: true,
    initialState: {
      density: 'compact',
      //columnFilters:[{ id: 'event_team_config_id', value: null }],
      columnVisibility: { event_team_config_id: false },
      sorting: [{ id: 'team_config_name' }, { id: 'team_config_name' }],
      columnPinning: {
        right: ['mrt-row-actions'],
      },
    },
    enableColumnFilters: false,
    state: { columnFilters },

    //enableGrouping: true,
    localization: MRT_Localization_SV,

    renderDetailPanel: ({ row }) =>
      <LagDeltagare data={row} />,

    renderTopToolbarCustomActions: () => (
      <div className="d-flex flex-row mb-3">



        <MDBBtn
          onClick={onnew}
          size='sm'
          className='me-2'
        >
          <MDBIcon fas icon="plus" className='me-2' />Lägg till lag
        </MDBBtn>


        <div className="vr"></div>
        <MDBBtnGroup aria-label='Basic example' size='sm' className="d-grid gap-2 d-md-block">
          <MDBBtn
            color='info'
            href='#'
            outline={activefilter != 0}
            active={activefilter == 0}
            onClick={(x) => onChangeFilter(0)}
          >
            Alla
          </MDBBtn>
          {filter.map((f, k) => (
            <>
              <MDBBtn
                key={k} href='#'
                outline={activefilter != f.value}
                active={activefilter == f.value}
                onClick={(x) => onChangeFilter(f.value)}
                color='info'
              >
                {f.text}
                <MDBBadge className='ms-2' color='light' light pill>{f.team_count}</MDBBadge>
              </MDBBtn>
            </>
          ))}
        </MDBBtnGroup>



      </div>
    ),
    renderToolbarInternalActions: ({ table }) => (

      <>
        <div>
          <div>
            <MDBBtn
              onClick={handleClick}
              size='sm'
              color='secondary'
            >
              Skriv ut / Export<MDBIcon fas icon="caret-down" className='ps-1' />
            </MDBBtn>
          </div>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}

            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem dense onClick={handleClose}>
              <MDBIcon fas icon="file-pdf" className='me-2' />test1 (PDF)
            </MenuItem>
            <MenuItem dense onClick={handleClose}>
              <MDBIcon fas icon="file-pdf" className='me-2' />test2 (PDF)
            </MenuItem>
            <MenuItem dense onClick={handleClose}>
              <MDBIcon fas icon="file-pdf" className='me-2' />test3 (PDF)
            </MenuItem>
            <MenuItem dense onClick={handleClose}>
              <MDBIcon fas icon="file-pdf" className='me-2' />test4 (PDF)
            </MenuItem>
          </Menu>
        </div>
        <MRT_ToggleFullScreenButton table={table} />
        <MDBBtn
          tag='a'
          size='sm'
          // onClick={toggleOpen}
          className="rounded outline btn bg-transparent shadow-0"
        >
          <MDBIcon fas icon="info-circle" color='info' size='lg' />
        </MDBBtn>
      </>

    ),
    renderRowActions: ({ row }) => (
      <>
        <MDBTooltip tag='a' wrapperProps={{ color: 'none', className: 'ripple ripple-surface m-1' }} title="Ändra laget">
          <MDBIcon fas icon='edit' color='primary' />
        </MDBTooltip>
        <div className="vr"></div>
        <MDBTooltip tag='a' wrapperProps={{ color: 'none', className: 'ripple ripple-surface m-1' }} title="Ta bort laget">
          <MDBIcon far icon='trash-alt' color='danger' />
        </MDBTooltip>
      </>
    ),
    // renderRowActionMenuItems: ({ closeMenu }) => [

    //   <MenuItem
    //     key={0}
    //     onClick={() => {
    //       // View profile logic...
    //       closeMenu();
    //     }}
    //     sx={{ m: 0 }}
    //   >
    //    <MDBIcon fas icon="file-pdf" className='me-2' /> View Profile
    //   </MenuItem>,
    //   <MenuItem
    //     key={1}
    //     onClick={() => {
    //       // Send email logic...
    //       closeMenu();
    //     }}
    //     sx={{ m: 0 }}
    //   >

    //     Send Email

    //   </MenuItem>,

    // ],
  });

  return (
    <>
      <MDBContainer className="py-4">

        <EventRubrik id={id} InfoBtn={false} />
        <MDBRow className='g-3 pt-4'>

          <MaterialReactTable
            table={table}
          />
          {/* </BorderCol> */}
        </MDBRow>
      </MDBContainer >


    </>
  );
}

function LagDeltagare(props) {

  const tableColumns = useMemo(
    () => [
      {
        accessorKey: 'full_name', //access nested data with dot notation
        header: 'Namn',
        size: 200,
        maxSize: 200,
        enableEditing: false,
      },
      {
        accessorKey: 'klass_name_display', //access nested data with dot notation
        header: 'Klass',
        size: 80,
        enableEditing: false,
      },
      {
        accessorKey: 'forening_name', //access nested data with dot notation
        header: 'Förening',
        size: 200,
        enableEditing: false,
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns: tableColumns,
    data: props.data.original.members,
    enableColumnActions: false,
    initialState: { density: 'compact' },
    enableColumnFilters: false,
    localization: MRT_Localization_SV,
    layoutMode: 'grid-no-grow',
    enableSorting: false
  });

  return (
    <>
      <MRT_Table
        table={table}
        className='ps-5'
      />
    </>
  );
}