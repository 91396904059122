import React, { useState, useRef, useEffect, useMemo } from 'react';
import {
  MDBBtn, MDBIcon,
  MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter,

} from 'mdb-react-ui-kit';
import { useParams, useNavigate, NavLink, json } from "react-router-dom";
import { fetch_get, fetch_post, fetch_delete } from "../../../services/common";


export function EventRubrik(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [eventModal, setEventModal] = useState(false);
  const toggleEventOpen = () => setEventModal(!eventModal);
  useEffect(() => {

    fetch_get('app/event/view/' + props.id)
      .then(data => {

        console.log('data', 'ö', data.data);
        setData(data.data);

        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, [loading]);

  return (
    <>
      {props.InfoBtn
        ?
        <>
          <h1 className="fs-1">Tävling - {data.name} <span className="fs-4">({data.start_date})</span><span><MDBBtn color='secondary' onClick={toggleEventOpen} size='sm' className='ms-3'><MDBIcon fas icon="ellipsis-h" /></MDBBtn></span></h1>
          <EventInfo eventModal={eventModal} setEventModal={setEventModal} toggleEventOpen={toggleEventOpen} event_data={data} />
        </>
        :
        <h1 className="fs-1">Tävling - {data.name} <span className="fs-4">({data.start_date})</span></h1>
      }
    </>
  );
}

function EventInfo(props) {

  return (
    <>

      <MDBModal tabIndex='-1' open={props.eventModal} setOpen={props.setEventModal} nonInvasive={false}>
        <MDBModalDialog size="lg">
          <MDBModalContent >
            <MDBModalHeader>
              <MDBModalTitle>Tävlingsinformation</MDBModalTitle>
              <MDBBtn
                type='button'
                className='btn-close'
                color='none'
                onClick={props.toggleEventOpen}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              {/* {props.id && (<EventOverviewPageModal {...props}></EventOverviewPageModal>)} */}
              <div className="d-flex flex-column flex-md-row justify-content-around">
                <div className="d-flex flex-column flex-fill mb-3 order-2 order-sm-1">
                  <div className="p-2 position-relative">
                    <div className="overview-label text-nowrap">Tävling</div>
                    {props.event_data.name || '-'}
                  </div>
                  <div className="p-2 position-relative">
                    <div className="overview-label text-nowrap">Arrangör</div>
                    {props.event_data.arrangorer || '-'}
                  </div>
                  <div className="p-2 position-relative">
                    <div className="overview-label text-nowrap">Typ Av tävling</div>
                    {props.event_data.type_name || '-'}
                  </div>
                  <div className="p-2 position-relative">
                    <div className="overview-label text-nowrap">Kategori</div>
                    {props.event_data.kategori_name || '-'}
                  </div>
                  <div className="p-2 position-relative overflow-wrap">
                    <div className="overview-label text-nowrap">Sportgren</div>
                    {props.event_data.d_sportgren
                      ? (props.event_data.d_sportgren.split(',').map(sg => <div>{sg}</div>))
                      : <div>-</div>
                    }
                  </div>
                  <hr className="hr" />
                  <div className="p-2 position-relative css-fix">
                    <div className="overview-label text-nowrap">Plats</div>
                    {props.event_data.plats || '-'}
                  </div>
                  <div className="p-2 position-relative">
                    <div className="overview-label text-nowrap">Hemsida</div>
                    <a href={props.event_data.hemsida} target='_new'>{props.event_data.hemsida || '-'}</a>
                  </div>
                  <hr className="hr" />
                  <div className="p-2 position-relative">
                    <div className="overview-label text-nowrap">Kontakt</div>
                    {props.event_data.kontakt_first_name || '-'}
                  </div>
                  <div className="p-2 position-relative">
                    <div className="overview-label text-nowrap">E-mail</div>
                    {props.event_data.kontakt_email || '-'}
                  </div>
                  <div className="p-2 position-relative">
                    <div className="overview-label text-nowrap">Telefon</div>
                    {props.event_data.kontakt_phone || '-'}
                  </div>
                </div>
                <div className="d-flex flex-column flex-fill mb-3 order-3 order-sm-2">
                  <div className="p-2 position-relative">
                    <div className="overview-label text-nowrap">Start datum</div>
                    {props.event_data.start_date || '-'}
                  </div>
                  <div className="p-2 position-relative">
                    <div className="overview-label text-nowrap">Slut datum</div>
                    {props.event_data.end_date || '-'}
                  </div>
                  <div className="p-2 position-relative">
                    <div className="overview-label text-nowrap">Öppnar för anmälan</div>
                    {props.event_data.anmalan_start_date || '-'}
                  </div>
                  <div className="p-2 position-relative">
                    <div className="overview-label text-nowrap">Sista anmälningsdatum</div>
                    {props.event_data.anmalan_end_date || '-'}
                  </div>
                  <div className="p-2 position-relative">
                    <div className="overview-label text-nowrap">Sista efteranmälningsdatum</div>
                    {props.event_data.efteranmalan_end_date || '-'}
                  </div>
                </div>
                <div className="d-flex flex-column flex-fill mb-3 order-1 order-sm-3">
                  <div className="p-2 position-relative" color={props.event_data.status_color}>
                    <div className="overview-label text-nowrap">Status</div>
                    {props.event_data.status_name || '-'}
                  </div>

                </div>
              </div>


            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn type='button' color='secondary' onClick={props.toggleEventOpen}>
                Stäng
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}

